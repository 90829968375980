<!-- eslint-disable no-mixed-spaces-and-tabs -->
<script setup lang="ts">
import loader from '../../loader.vue';
import axios from 'axios';
import { reactive, watch } from 'vue';
import { useToast } from 'vue-toastification';
import toastText from '../../toastText.vue';
import insucessoIcon from '../../../assets/icons/insucesso.vue';
import sucessoIcon from '../../../assets/icons/sucesso.vue';
import infoIcon from '../../../assets/icons/info.vue';
import avisoIcon from '../../../assets/icons/aviso.vue';
import { useLogin } from '../../../stores/login';
import { vehicle } from '../../../stores/vehicle';
import { useInfosUser } from '../../../stores/userInfos';
import { useChangePassword } from '../../../stores/changePassword';
import { useForgotPassword } from '../../../stores/forgotPassword';
import { useControlUser } from '../../../stores/userControl';
import { useGroupTeamControl } from '../../../stores/groupTeamControl';
import { useEvaluation } from '../../../stores/evaluation';
import { useControlAcessUser } from '../../../stores/userControlUserAcess';
import { useEvaluationView } from '../../../stores/evaluationView';
import { useRequestEvaluation } from '../../../stores/requestEvaluation';
import { useCompanyControl } from '../../../stores/companyControl';
import { useAutoPay } from '../../../stores/autoPay';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { usePricing } from '../../../stores/pricing';
import { useFilters } from '../../../stores/filters';
import { ref } from 'vue';
import { REVIEWS_STATUS_CONFIG } from '@/utils';
const userInfos = useInfosUser();
const pricing = usePricing();
const vehicleInfos = vehicle();
const loginStore = useLogin();
const changePassword = useChangePassword();
const requestEvalution = useRequestEvaluation();
const forgotPassword = useForgotPassword();
const controlUser = useControlUser();
const controlGroupTeam = useGroupTeamControl();
const evaluation = useEvaluation();
const controlAcess = useControlAcessUser();
const evaluationView = useEvaluationView();
const autoPay = useAutoPay();
const company = useCompanyControl();
const filters = useFilters();
const pagina = ref(1);
const { novaSenha, confirmSenha, senhaAntiga, errorChangePassword, errorTextChangePassword, clickButtonChangePassword, haveAErrorChangePassword } = storeToRefs(changePassword);
const { emailForgot, errorDesc, errorForgot, clickButtonForgot, haveAErrorForgotPassword, newPasswordForgot, confirmPasswordForgot, passwordTokenForgot } = storeToRefs(forgotPassword);
const { haveAError, login, password, idUsuario, nome, sobrenome, email, cargo, grupo, equipe, empresa, id_cargo, id_equipe, id_grupo, id_empresa, errorLogin, errorText, clickButton, loginAction, signature, signatureModalOpen, errorUpdateSignature, errorUpdateSignatureText, passwordUpdateSignature, clickButtonUpdateSignature, loadNewSignature, acessos, modalDesvioPagamento, clickedButtonDesvioPagamento, errorDesvioPagamento, errorTextDesvioPagamento, modalNotificationOpen, notificacoesAtivas, infosSearch } = storeToRefs(loginStore);
const { modalAddUserOpen, modalDeleteUser, modalControlAcessUser, newUserEmail, newUserName, newUserPassword, newUserGroupId, newUserJobId, newUserSurname, newUserTeamId, clickButtonUserControl, haveAErrorUserControl, errorTextUserControl, errorUserControl, editUserEmail, editUserName, editUserSurname, editUserGroupId, editUserJobId, editUserTeamId, deleteUserPassword, refreshListUsers, transfUserName, transfUserSurname, transfUserGroup, transfUserGroupId, transfUserId, transfUserTeam, transfUserTeamId, editUserId, editUserTeam, editUserGroup } = storeToRefs(controlUser);
const { modalDeleteGroup, modalAddGroup, modalAddTeam, editGroupName, groupId, teamId, editTeam, modalDeleteTeam, clickButtonGroupTeamControl, errorTextGroupTeamControl, refreshListGroups, haveAErrorGroupTeamControl, errorGroupTeamControl, editTeamName, deleteGroupPassword, newTeamGroup, newTeamGroupId, newGroupName, editGroupUf, newGroupUf } = storeToRefs(controlGroupTeam);
const { currentStep, tipoAvaliacao, km, chaveReserva, manual, documentExercise, garantiaFabrica, garantiaData, revisao, revisaoData, kmRevisao, registrosCsvs, fotosAvaliacao, respostasAvaliacao, respostasHigienizacaoPolimento, tipoNegociacao, convite, clickedSendEvaluation, conviteValue, outrosCustosValor, outrosCustosDescricao } = storeToRefs(evaluation);
const { placa, renavam, anoFabricacao, anoModelo, codigo_fipe, id_fipe, marca, modelo, versao, historicosFipe, idCor, cor, carroceria, transmissao, combustivel, portas, tetoSolar, blindagem, arCondicionado, veiculo_tipo, eletricoAval } = storeToRefs(vehicleInfos);
const { desktop, mobile } = storeToRefs(userInfos);
const { clickedButtonAdquireVeiculo, preco, comentario, errorEvaluationView, errorEvaluationViewText, id_avaliacao, avaliacao, avaliacao_rapida, classificacao_metal, fipe, precificacao, precificado, status, usuario, veiculo_avaliado, veiculo_tipos, auto_pay, avaliacaoEscolhida, modalEditaValor, editValorFinal, tituloEdita, tipoEdita, errorTextValorFinal, errorValorFinal, clickedButtonEditValorFinal, clickedButtonEditRenegociacao, valorTempoVendaNovo, valorHigienizacaoEsteticaNovo, valorMargemNegociacaoNovo, valorMediaInternetNovo, valorProvisaoRevisoesNovo, valorReparosNovo, valorLucroNovo, indexButton, modalComentarioRenegociacao, clickedAceitaRenegociacao, clickedRecusaRenegociacao, clickedVetaRenegociacao, typeComentario, comentarioRenegociacao, clickedButtonPagaAvaliacao, clickedButtonAdquirirAvaliacao, clickedButtonVenderVeiculo, valorOutrosCustosNovo } = storeToRefs(evaluationView);
const { clickedAcess, acessosControl, userId, refreshListAcess, clickedResetAcess } = storeToRefs(controlAcess);
const { clickedSaveChangesDestination, clickedResetRulesDestination, kmMaximaRepasse, metal, step, tipoVeiculo, idRegraDestino, errorRulesDestination, errorTextRulesDestination, haveAErrorRulesDestination, acrescimoParaReparo, aliquotaIcms, aliquotaIpva, aliquotaPisCofins, carenciaFloorplan, clickedResetRulesPricing, clickedSaveChangesPricing, depreciacaoCapital, depreciacaoCapitalValue, depreciacaoFipe, errorRulesPricing, errorTextRulesPricing, haveAErrorRulesPricing, higienizacaoCusto, lucro, lucroMaximo, lucroMinimo, lucroRepassador, lucroRepassadorMaximo, lucroRepassadorMinimo, margemMaxima, margemMinima, margemNegociacao, percentualFipeCustosRevisao, polimentoCusto, tempoEstoqueMaximo, tempoEstoqueMinimo, tipoPrecificacao, idRegraPrecificacao, uf, ufId, veiculoTipo, eletrico } = storeToRefs(pricing);
const { modelSolicitaOpen, comentarioSolicita, idSolicitacao, loadLists, placaSolicita, clickedSolicitaAvaliacao, chaveReservaCliente, documentExerciseSolicita, emailClienteSolicita, garantiaFabricaCliente, garantiaFabricaDataCliente, manualCliente, nomeClienteSolicita, revisaoFabricaCliente, revisaoFabricaDataCliente, revisaoFabricaKm, telefoneClienteSolicita, tipoNegociacaoCliente, vendedorResponsavelId } = storeToRefs(requestEvalution);
const { classificacaoMetalAutoPay, tipoVeiculoAutoPay, clickedAutoPay, regras, regrasDefault, alteracoes, reload } = storeToRefs(autoPay);
const { companyId, editCompanyName, modalAddCompany, modalEditCompany, refreshListCompany, clickButtonCompanyControl, editAvaliacoes_mensais, companyAdminEmail, companyAdminName, companyAdminPassword, companyAdminSurname, companyGroupName, companyName, companyTeamName, companyUf, companyAvaliacoesMensais, errorAddCompany, errorTextAddCompany } = storeToRefs(company);
const router = useRouter();
const route = useRoute();
const toast = useToast();
defineProps<{
	type: 'deactive' | 'active' | 'empty' | 'destructive' | 'confirm' | 'destructiveFill' | 'activeUnfill' | 'advance' | 'custom';
	action: string;
	color?: 'deactive' | 'active' | 'empty' | 'destructive' | 'confirm' | 'destructiveFill' | 'activeUnfill' | 'advance' | 'confirmDeactive';
	customHeight?: 32 | 48 | 52;
}>();
watch([() => errorForgot.value, () => errorDesc.value, () => emailForgot.value], ([newVal1, newVal2, newVal3], [oldVal1, oldVal2, oldVal3]) => {
	if (haveAErrorForgotPassword.value == true) {
		if (oldVal2 == 'E-mail inválido') {
			if (oldVal3 !== newVal3) {
				errorForgot.value = false;
				errorDesc.value = '';
				haveAErrorForgotPassword.value = false;
			}
		}
		if (oldVal2 == 'Email não encontrado') {
			if (oldVal3 !== newVal3) {
				errorForgot.value = false;
				errorDesc.value = '';
				haveAErrorForgotPassword.value = false;
			}
		}
	}
});
watch([() => errorForgot.value, () => errorDesc.value, () => newPasswordForgot.value, () => confirmPasswordForgot.value], ([newVal1, newVal2, newVal3, newVal4], [oldVal1, oldVal2, oldVal3, oldVal4]) => {
	if (haveAErrorForgotPassword.value == true) {
		if (oldVal2 == 'Tamanho minimo de senha 6 digítos') {
			if (oldVal3 !== newVal3) {
				errorForgot.value = false;
				errorDesc.value = '';
				haveAErrorForgotPassword.value = false;
			}
		}
		if (oldVal2 == 'Senhas devem ser iguais') {
			if (oldVal4 !== newVal4) {
				errorForgot.value = false;
				errorDesc.value = '';
				haveAErrorForgotPassword.value = false;
			}
		}
	}
});
watch([() => errorLogin.value, () => errorText.value, () => login.value, () => password.value], ([newVal1, newVal2, newVal3, newVal4], [oldVal1, oldVal2, oldVal3, oldVal4]) => {
	if (haveAError.value == true) {
		if (oldVal2 == 'Usuário não cadastrado') {
			if (oldVal3 !== newVal3) {
				errorLogin.value = false;
				errorText.value = '';
				haveAError.value = false;
			}
		}
		if (oldVal2 == 'Senha incorreta') {
			if (oldVal4 !== newVal4) {
				errorLogin.value = false;
				errorText.value = '';
				haveAError.value = false;
			}
		}
		if (oldVal2 == 'E-mail inválido') {
			if (oldVal3 !== newVal3) {
				errorLogin.value = false;
				errorText.value = '';
				haveAError.value = false;
			}
		}
		if (oldVal2 == 'Tamanho minimo de senha 6 digítos') {
			if (oldVal4 !== newVal4) {
				errorLogin.value = false;
				errorText.value = '';
				haveAError.value = false;
			}
		}
	}
});
watch([() => errorChangePassword.value, () => errorTextChangePassword.value, () => senhaAntiga.value, () => novaSenha.value, () => confirmSenha.value], ([newVal1, newVal2, newVal3, newVal4, newVal5], [oldVal1, oldVal2, oldVal3, oldVal4, oldVal5]) => {
	if (haveAErrorChangePassword.value == true) {
		if (oldVal2 == 'Senha incorreta' || oldVal2 == 'Senha antiga não informada' || oldVal2 == 'Senha com no minimo 6 caracteres' || oldVal2 == 'Senha não informada') {
			if (oldVal3 !== newVal3) {
				errorChangePassword.value = false;
				errorTextChangePassword.value = '';
				haveAErrorChangePassword.value = false;
			}
		}
		if (oldVal2 == 'Nova senha não informada' || oldVal2 == 'Nova senha com no minimo 6 caracteres') {
			if (oldVal4 !== newVal4) {
				errorChangePassword.value = false;
				errorTextChangePassword.value = '';
				haveAErrorChangePassword.value = true;
			}
		}
		if (oldVal2 == 'Senhas devem ser iguais') {
			if (oldVal5 !== newVal5) {
				errorChangePassword.value = false;
				errorTextChangePassword.value = '';
				haveAErrorChangePassword.value = true;
			}
		}
	}
});
watch([() => errorUserControl.value, () => errorTextUserControl.value, () => newUserEmail.value, () => newUserPassword.value, () => newUserName.value, () => newUserSurname.value, () => newUserJobId.value, () => newUserGroupId.value, () => editUserName.value, () => editUserSurname.value, () => deleteUserPassword.value, () => transfUserTeam.value, () => transfUserGroup.value, () => transfUserName.value], ([newVal1, newVal2, newVal3, newVal4, newVal5, newVal6, newVal7, newVal8, newVal9, newVal10, newVal11, newVal12, newVal13, newVal14], [oldVal1, oldVal2, oldVal3, oldVal4, oldVal5, oldVal6, oldVal7, oldVal8, oldVal9, oldVal10, oldVal11, oldVal12, oldVal13, oldVal14]) => {
	if (haveAErrorUserControl.value == true) {
		if (oldVal2 == 'Email não informado' || oldVal2 == 'Formato de email inválido' || oldVal2 == 'Usuário já cadastrado') {
			if (oldVal3 !== newVal3) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Senha não informada' || oldVal2 == 'Senha com no minimo 6 caracteres' || oldVal2 == 'Senha incorreta') {
			if (oldVal4 !== newVal4 || oldVal11 !== newVal11) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Nome não informado') {
			if (oldVal5 !== newVal5 || oldVal9 !== newVal9) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Sobrenome não informado') {
			if (oldVal6 !== newVal6 || oldVal10 !== newVal10) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Cargo não informado' || oldVal2 == 'Cargo informado inexistente') {
			if (oldVal7 !== newVal7) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Grupo não informado' || oldVal2 == 'Grupo informado inexistente') {
			if (oldVal8 !== newVal8) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
			if (oldVal13 !== newVal13) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Equipe não informada') {
			if (oldVal12 !== newVal12) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
		if (oldVal2 == 'Usuário não informado') {
			if (oldVal14 !== newVal14) {
				errorUserControl.value = true;
				errorTextUserControl.value = '';
				haveAErrorUserControl.value = false;
			}
		}
	}
});
watch([() => errorGroupTeamControl.value, () => errorTextGroupTeamControl.value, () => editGroupName.value, () => editTeam.value, () => editTeamName.value, () => deleteGroupPassword.value, () => newGroupName.value], ([newVal1, newVal2, newVal3, newVal4, newVal5, newVal6, newVal7], [oldVal1, oldVal2, oldVal3, oldVal4, oldVal5, oldVal6, oldVal7]) => {
	if (haveAErrorGroupTeamControl.value == true) {
		if (oldVal2 == 'Nome do grupo não informado' || oldVal2 == 'Grupo existente na equipe selecionada') {
			if (oldVal3 !== newVal3 || oldVal7 !== newVal7) {
				errorGroupTeamControl.value = false;
				errorTextGroupTeamControl.value = '';
				haveAErrorGroupTeamControl.value = false;
			}
		}
		if (oldVal2 == 'Senha incorreta') {
			if (oldVal6 !== newVal6) {
				errorGroupTeamControl.value = false;
				errorTextGroupTeamControl.value = '';
				haveAErrorGroupTeamControl.value = false;
			}
		}
		if (oldVal2 == 'Equipe informada inexistente' || oldVal2 == 'Equipe não informada') {
			if (oldVal4 !== newVal4) {
				errorGroupTeamControl.value = false;
				errorTextGroupTeamControl.value = '';
				haveAErrorGroupTeamControl.value = false;
			}
		}
		if (oldVal2 == 'Grupo existente na equipe selecionada') {
			if (oldVal3 !== newVal3 || oldVal4 !== newVal4) {
				errorGroupTeamControl.value = false;
				errorTextGroupTeamControl.value = '';
				haveAErrorGroupTeamControl.value = false;
			}
		}
	}
});
watch([() => errorGroupTeamControl.value, () => errorTextGroupTeamControl.value, () => editGroupName.value, () => editTeam.value, () => editTeamName.value, () => deleteGroupPassword.value, () => newTeamGroup.value, () => newGroupName.value], ([newVal1, newVal2, newVal3, newVal4, newVal5, newVal6, newVal7, newVal8], [oldVal1, oldVal2, oldVal3, oldVal4, oldVal5, oldVal6, oldVal7, oldVal8]) => {
	if (haveAErrorGroupTeamControl.value == true) {
		if (oldVal2 == 'Nome da equipe não informado' || oldVal2 == 'Equipe existente na empresa') {
			if (oldVal5 !== newVal5 || oldVal7 !== newVal7) {
				errorGroupTeamControl.value = false;
				errorTextGroupTeamControl.value = '';
				haveAErrorGroupTeamControl.value = false;
			}
		}
	}
});
watch([() => errorUpdateSignature.value, () => errorUpdateSignatureText.value, () => passwordUpdateSignature.value], ([newVal1, newVal2, newVal3], [oldVal1, oldVal2, oldVal3]) => {
	if (oldVal2 == 'Senha não informada ou menor que 6 caracteres') {
		if (oldVal3 !== newVal3) {
			errorUpdateSignature.value = false;
			errorTextGroupTeamControl.value = '';
		}
	}
});
watch([() => errorRulesDestination.value, () => errorTextRulesDestination.value, () => kmMaximaRepasse.value], ([newVal1, newVal2, newVal3], [oldVal1, oldVal2, oldVal3]) => {
	if (haveAErrorRulesDestination.value == true) {
		if (oldVal2 == 'Km máxima tem que ser maior que 0 e menor que 1 milhão') {
			if (oldVal3 !== newVal3) {
				errorRulesDestination.value = false;
				errorTextRulesDestination.value = '';
				haveAErrorRulesDestination.value = false;
			}
		}
	}
});
watch([() => errorLogin.value, () => errorText.value, () => password.value, () => login.value], ([newVal1, newVal2, newVal3, newVal4], [oldVal1, oldVal2, oldVal3, oldVal4]) => {
	if (oldVal2 == 'Tamanho minimo de senha 6 digítos') {
		if (newVal3 !== oldVal3) {
			errorLogin.value = false;
			errorText.value = '';
		}
	}
	if (oldVal2 == 'Formato de e-mail inválido') {
		if (newVal4 !== oldVal4) {
			errorLogin.value = false;
			errorText.value = '';
		}
	}
	if (oldVal2 == 'E-mail ou senha não correspondem') {
		if (newVal3 !== oldVal3 || newVal4 !== oldVal4) {
			errorLogin.value = false;
			errorText.value = '';
		}
	}
});
watch(loginAction, (val) => {
	if (val == true) {
		goAhead('active', 'login');
	}
});
watch(preco, (newVal, oldVal) => {
	if (newVal !== oldVal) {
		errorEvaluationView.value = false;
		errorEvaluationViewText.value = '';
	}
});
function goAhead(type: string, action: string) {
	if (action == 'addNewTeam') {
		modalAddTeam.value = true;
	}
	if (action == 'addNewCompany') {
		modalAddCompany.value = true;
	}
	if (action == 'deleteTeam') {
		modalDeleteTeam.value = true;
	}
	if (action == 'backRoute') {
		router.back();
	}
	if (type == 'confirm') {
		if (action == 'addNewUser') {
			if (clickButtonUserControl.value == false) {
				clickButtonUserControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (newUserEmail.value.length > 0) {
					if (newUserName.value.length > 0) {
						if (newUserSurname.value.length > 0) {
							if (newUserJobId.value > 0) {
								if (newUserGroupId.value > 0) {
									axios
										.post(
											`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/cadastraUsuario`,
											{
												nome: newUserName.value,
												sobrenome: newUserSurname.value,
												email: newUserEmail.value,
												senha: newUserPassword.value,
												id_cargo: newUserJobId.value,
												id_grupo: newUserGroupId.value,
												id_empresa: id_empresa.value
											},
											{
												headers: {
													Authorization: 'Bearer ' + tokenUser,
													'Content-Type': 'application/json'
												}
											}
										)
										.then((res) => {
											toast.success(
												{
													component: toastText,
													props: {
														h1Text: 'Novo usuário cadastrado',
														pText: `${res.data.mensagem}`
													}
												},
												{
													timeout: 2500,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: sucessoIcon,
													rtl: false,
													onClose() {
														refreshListUsers.value = true;
														setTimeout(() => {
															controlUser.$reset();
														}, 100);
													}
												}
											);
											setTimeout(() => {
												clickButtonUserControl.value = false;
											}, 1500);
										})
										.catch((err) => {
											haveAErrorUserControl.value = true;
											if (err.message == 'Network Error') {
												toast.error(
													{
														component: toastText,
														props: {
															h1Text: 'Serviço indisponível',
															pText: 'serviço passando por instabilidade'
														}
													},
													{
														timeout: 5000,
														closeOnClick: false,
														pauseOnFocusLoss: false,
														pauseOnHover: false,
														draggable: true,
														draggablePercent: 0.8,
														showCloseButtonOnHover: false,
														hideProgressBar: false,
														closeButton: false,
														icon: insucessoIcon,
														rtl: false
													}
												);
												setTimeout(() => {
													clickButtonUserControl.value = false;
												}, 1500);
											} else {
												errorUserControl.value = true;
												errorTextUserControl.value = err.response.data.mensagem;
												setTimeout(() => {
													clickButtonUserControl.value = false;
												}, 1500);
											}
										});
								} else {
									errorUserControl.value = true;
									errorTextUserControl.value = 'Grupo não informado';
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								}
							} else {
								errorUserControl.value = true;
								errorTextUserControl.value = 'Cargo não informado';
								setTimeout(() => {
									clickButtonUserControl.value = false;
								}, 1500);
							}
						} else {
							errorUserControl.value = true;
							errorTextUserControl.value = 'Sobrenome não informado';
							setTimeout(() => {
								clickButtonUserControl.value = false;
							}, 1500);
						}
					} else {
						errorUserControl.value = true;
						errorTextUserControl.value = 'Nome não informado';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					}
				} else {
					errorUserControl.value = true;
					errorTextUserControl.value = 'E-mail não informado';
					setTimeout(() => {
						clickButtonUserControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'addNewGroup') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (newGroupName.value.length > 0) {
					if (teamId.value > 0) {
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/grupos/cadastraGrupo`,
								{
									grupo: newGroupName.value,
									id_equipe: teamId.value,
									uf_alvo: newGroupUf.value
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								toast.success(
									{
										component: toastText,
										props: {
											h1Text: 'Novo grupo cadastrado',
											pText: 'Grupo cadastrado com sucesso'
										}
									},
									{
										timeout: 2500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: sucessoIcon,
										rtl: false,
										onClose() {
											refreshListGroups.value = true;
											setTimeout(() => {
												controlGroupTeam.$reset();
											}, 100);
										}
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							})
							.catch((err) => {
								haveAErrorGroupTeamControl.value = true;
								if (err.message == 'Network Error') {
									toast.error(
										{
											component: toastText,
											props: {
												h1Text: 'Serviço indisponível',
												pText: 'serviço passando por instabilidade'
											}
										},
										{
											timeout: 5000,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: insucessoIcon,
											rtl: false
										}
									);
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								} else {
									if (err.response.data.mensagem == 'Nome de grupo já cadastrado nesta equipe ou empresa') {
										toast.warning(
											{
												component: toastText,
												props: {
													h1Text: err.response.data.mensagem,
													pText: 'Altere o nome ou escolha outra equipe'
												}
											},
											{
												timeout: 2500,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: avisoIcon,
												rtl: false
											}
										);
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									} else {
										errorGroupTeamControl.value = true;
										errorTextGroupTeamControl.value = err.response.data;
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									}
								}
							});
					} else {
						errorGroupTeamControl.value = true;
						errorTextGroupTeamControl.value = 'Equipe não informada';
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					}
				} else {
					errorGroupTeamControl.value = true;
					errorTextGroupTeamControl.value = 'Nome do grupo não informado';
					setTimeout(() => {
						clickButtonGroupTeamControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'addNewTeam') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];

				if (desktop.value) {
					if (newTeamGroup.value.length > 0) {
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/equipes/cadastraEquipe`,
								{
									equipe: newTeamGroup.value,
									id_empresa: id_empresa.value
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								toast.success(
									{
										component: toastText,
										props: {
											h1Text: 'Nova equipe cadastrada',
											pText: res.data.mensagem
										}
									},
									{
										timeout: 2500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: sucessoIcon,
										rtl: false,
										onClose() {
											refreshListGroups.value = true;
											setTimeout(() => {
												controlGroupTeam.$reset();
											}, 100);
										}
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							})
							.catch((err) => {
								haveAErrorGroupTeamControl.value = true;
								if (err.message == 'Network Error') {
									toast.error(
										{
											component: toastText,
											props: {
												h1Text: 'Serviço indisponível',
												pText: 'serviço passando por instabilidade'
											}
										},
										{
											timeout: 5000,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: insucessoIcon,
											rtl: false
										}
									);
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								} else {
									errorGroupTeamControl.value = false;
									errorTextGroupTeamControl.value = '';
									if (err.response.data.mensagem == 'Nome de equipe já cadastrado nesta empresa ') {
										toast.warning(
											{
												component: toastText,
												props: {
													h1Text: err.response.data.mensagem,
													pText: 'Altere o nome para criação da equipe'
												}
											},
											{
												timeout: 2500,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: avisoIcon,
												rtl: false
											}
										);
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									} else {
										errorGroupTeamControl.value = true;
										errorTextGroupTeamControl.value = err.response.data.mensagem;
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									}
								}
							});
					} else {
						errorGroupTeamControl.value = true;
						errorTextGroupTeamControl.value = 'Nome da equipe não informado';
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					}
				} else {
					if (editTeamName.value.length > 0) {
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/equipes/cadastraEquipe`,
								{
									equipe: editTeamName.value,
									id_empresa: id_empresa.value
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								toast.success(
									{
										component: toastText,
										props: {
											h1Text: 'Nova equipe cadastrada',
											pText: res.data.mensagem
										}
									},
									{
										timeout: 2500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: sucessoIcon,
										rtl: false,
										onClose() {
											refreshListGroups.value = true;
											setTimeout(() => {
												controlGroupTeam.$reset();
											}, 100);
										}
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							})
							.catch((err) => {
								haveAErrorGroupTeamControl.value = true;
								if (err.message == 'Network Error') {
									toast.error(
										{
											component: toastText,
											props: {
												h1Text: 'Serviço indisponível',
												pText: 'serviço passando por instabilidade'
											}
										},
										{
											timeout: 5000,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: insucessoIcon,
											rtl: false
										}
									);
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								} else {
									errorGroupTeamControl.value = false;
									errorTextGroupTeamControl.value = '';
									if (err.response.data.mensagem == 'Nome de equipe já cadastrado nesta empresa ') {
										toast.warning(
											{
												component: toastText,
												props: {
													h1Text: err.response.data.mensagem,
													pText: 'Altere o nome para criação da equipe'
												}
											},
											{
												timeout: 2500,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: avisoIcon,
												rtl: false
											}
										);
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									} else {
										errorGroupTeamControl.value = true;
										errorTextGroupTeamControl.value = err.response.data.mensagem;
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									}
								}
							});
					} else {
						errorGroupTeamControl.value = true;
						errorTextGroupTeamControl.value = 'Nome da equipe não informado';
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					}
				}
			}
		}
		if (action == 'addNewCompany') {
			if (clickButtonCompanyControl.value == false) {
				clickButtonCompanyControl.value = true;
				const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (companyName.value !== '') {
					if (companyTeamName.value !== '') {
						if (companyGroupName.value !== '') {
							if (companyAdminName.value !== '') {
								if (companyAdminSurname.value !== '') {
									if (re.test(companyAdminEmail.value)) {
										if (companyAdminPassword.value.length >= 8) {
											if (companyUf.value !== '') {
												if (companyAvaliacoesMensais.value !== '') {
													if (desktop.value) {
														axios
															.post(
																`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/empresas/cadastraEmpresa`,
																{
																	empresa: companyName.value == '' ? null : companyName.value,
																	equipe: companyTeamName.value == '' ? null : companyTeamName.value,
																	grupo: companyGroupName.value == '' ? null : companyGroupName.value,
																	nome: companyAdminName.value == '' ? null : companyAdminName.value,
																	sobrenome: companyAdminSurname.value == '' ? null : companyAdminSurname.value,
																	email: companyAdminEmail.value == '' ? null : companyAdminEmail.value,
																	senha: companyAdminPassword.value == '' ? null : companyAdminPassword.value,
																	uf_alvo: companyUf.value == '' ? null : companyUf.value,
																	avaliacoes_mensais: companyAvaliacoesMensais.value == '' ? null : companyAvaliacoesMensais.value.replace('.', '').replace(',', '')
																},
																{
																	headers: {
																		Authorization: 'Bearer ' + tokenUser,
																		'Content-Type': 'application/json'
																	}
																}
															)
															.then((res) => {
																toast.success(
																	{
																		component: toastText,
																		props: {
																			h1Text: 'Nova empresa cadastrada',
																			pText: res.data.mensagem
																		}
																	},
																	{
																		timeout: 2500,
																		closeOnClick: false,
																		pauseOnFocusLoss: false,
																		pauseOnHover: false,
																		draggable: true,
																		draggablePercent: 0.8,
																		showCloseButtonOnHover: false,
																		hideProgressBar: false,
																		closeButton: false,
																		icon: sucessoIcon,
																		rtl: false,
																		onClose() {
																			refreshListCompany.value = true;
																			setTimeout(() => {
																				company.$reset();
																			}, 100);
																		}
																	}
																);
																setTimeout(() => {
																	clickButtonCompanyControl.value = false;
																}, 1500);
															})
															.catch((err) => {
																if (err.message == 'Network Error') {
																	toast.error(
																		{
																			component: toastText,
																			props: {
																				h1Text: 'Serviço indisponível',
																				pText: 'serviço passando por instabilidade'
																			}
																		},
																		{
																			timeout: 5000,
																			closeOnClick: false,
																			pauseOnFocusLoss: false,
																			pauseOnHover: false,
																			draggable: true,
																			draggablePercent: 0.8,
																			showCloseButtonOnHover: false,
																			hideProgressBar: false,
																			closeButton: false,
																			icon: insucessoIcon,
																			rtl: false,
																			onClose() {
																				setTimeout(() => {
																					errorAddCompany.value = false;
																					errorTextAddCompany.value = '';
																					clickButtonCompanyControl.value = false;
																				}, 1500);
																			}
																		}
																	);
																} else {
																	toast.error(
																		{
																			component: toastText,
																			props: {
																				h1Text: 'Um erro foi encontrado',
																				pText: err.response.data.mensagem
																			}
																		},
																		{
																			timeout: 5000,
																			closeOnClick: false,
																			pauseOnFocusLoss: false,
																			pauseOnHover: false,
																			draggable: true,
																			draggablePercent: 0.8,
																			showCloseButtonOnHover: false,
																			hideProgressBar: false,
																			closeButton: false,
																			icon: insucessoIcon,
																			rtl: false,
																			onClose() {
																				errorAddCompany.value = false;
																				errorTextAddCompany.value = '';
																				clickButtonCompanyControl.value = false;
																			}
																		}
																	);
																}
															});
													} else {
														axios
															.post(
																`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/empresas/cadastraEmpresa`,
																{
																	empresa: companyName.value == '' ? null : companyName.value,
																	equipe: companyTeamName.value == '' ? null : companyTeamName.value,
																	grupo: companyGroupName.value == '' ? null : companyGroupName.value,
																	nome: companyAdminName.value == '' ? null : companyAdminName.value,
																	sobrenome: companyAdminSurname.value == '' ? null : companyAdminSurname.value,
																	email: companyAdminEmail.value == '' ? null : companyAdminEmail.value,
																	senha: companyAdminPassword.value == '' ? null : companyAdminPassword.value,
																	uf_alvo: companyUf.value == '' ? null : companyUf.value,
																	avaliacoes_mensais: companyAvaliacoesMensais.value == '' ? null : companyAvaliacoesMensais.value.replace('.', '').replace(',', '')
																},
																{
																	headers: {
																		Authorization: 'Bearer ' + tokenUser,
																		'Content-Type': 'application/json'
																	}
																}
															)
															.then((res) => {
																toast.success(
																	{
																		component: toastText,
																		props: {
																			h1Text: 'Nova empresa cadastrada',
																			pText: res.data.mensagem
																		}
																	},
																	{
																		timeout: 2500,
																		closeOnClick: false,
																		pauseOnFocusLoss: false,
																		pauseOnHover: false,
																		draggable: true,
																		draggablePercent: 0.8,
																		showCloseButtonOnHover: false,
																		hideProgressBar: false,
																		closeButton: false,
																		icon: sucessoIcon,
																		rtl: false,
																		onClose() {
																			refreshListCompany.value = true;
																			setTimeout(() => {
																				company.$reset();
																			}, 100);
																		}
																	}
																);
																setTimeout(() => {
																	clickButtonCompanyControl.value = false;
																}, 1500);
															})
															.catch((err) => {
																if (err.message == 'Network Error') {
																	toast.error(
																		{
																			component: toastText,
																			props: {
																				h1Text: 'Serviço indisponível',
																				pText: 'serviço passando por instabilidade'
																			}
																		},
																		{
																			timeout: 5000,
																			closeOnClick: false,
																			pauseOnFocusLoss: false,
																			pauseOnHover: false,
																			draggable: true,
																			draggablePercent: 0.8,
																			showCloseButtonOnHover: false,
																			hideProgressBar: false,
																			closeButton: false,
																			icon: insucessoIcon,
																			rtl: false,
																			onClose() {
																				errorAddCompany.value = false;
																				errorTextAddCompany.value = '';
																				clickButtonCompanyControl.value = false;
																			}
																		}
																	);
																} else {
																	toast.error(
																		{
																			component: toastText,
																			props: {
																				h1Text: 'Um erro foi encontrado',
																				pText: err.response.data.mensagem
																			}
																		},
																		{
																			timeout: 5000,
																			closeOnClick: false,
																			pauseOnFocusLoss: false,
																			pauseOnHover: false,
																			draggable: true,
																			draggablePercent: 0.8,
																			showCloseButtonOnHover: false,
																			hideProgressBar: false,
																			closeButton: false,
																			icon: insucessoIcon,
																			rtl: false,
																			onClose() {
																				errorAddCompany.value = false;
																				errorTextAddCompany.value = '';
																				clickButtonCompanyControl.value = false;
																			}
																		}
																	);
																}
															});
													}
												} else {
													errorAddCompany.value = true;
													errorTextAddCompany.value = 'Avaliações mensais não informadas';
													setTimeout(() => {
														clickButtonCompanyControl.value = false;
													}, 1500);
												}
											} else {
												errorAddCompany.value = true;
												errorTextAddCompany.value = 'Uf não informada';
												setTimeout(() => {
													clickButtonCompanyControl.value = false;
												}, 1500);
											}
										} else {
											errorAddCompany.value = true;
											errorTextAddCompany.value = 'Senha deve ter mais que 8 digitos';
											setTimeout(() => {
												clickButtonCompanyControl.value = false;
											}, 1500);
										}
									} else {
										errorAddCompany.value = true;
										errorTextAddCompany.value = 'Email inválido';
										setTimeout(() => {
											clickButtonCompanyControl.value = false;
										}, 1500);
									}
								} else {
									errorAddCompany.value = true;
									errorTextAddCompany.value = 'Sobrenome do admin não informado';
									setTimeout(() => {
										clickButtonCompanyControl.value = false;
									}, 1500);
								}
							} else {
								errorAddCompany.value = true;
								errorTextAddCompany.value = 'Nome do admin não informado';
								setTimeout(() => {
									clickButtonCompanyControl.value = false;
								}, 1500);
							}
						} else {
							errorAddCompany.value = true;
							errorTextAddCompany.value = 'Nome do grupo não informado';
							setTimeout(() => {
								clickButtonCompanyControl.value = false;
							}, 1500);
						}
					} else {
						errorAddCompany.value = true;
						errorTextAddCompany.value = 'Nome da equipe não informado';
						setTimeout(() => {
							clickButtonCompanyControl.value = false;
						}, 1500);
					}
				} else {
					errorAddCompany.value = true;
					errorTextAddCompany.value = 'Nome da empresa não informado';
					setTimeout(() => {
						clickButtonCompanyControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'requestEvaluation') {
			if (clickedSolicitaAvaliacao.value == false) {
				clickedSolicitaAvaliacao.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/solicitaAvaliacao`,
						{
							usuario: {
								id_usuario: idUsuario.value,
								nome: nome.value,
								sobrenome: sobrenome.value,
								empresa: {
									id_empresa: id_empresa.value,
									empresa: empresa.value
								},
								equipe: {
									id_equipe: id_equipe.value,
									equipe: equipe.value
								},
								grupo: {
									id_grupo: id_grupo.value,
									grupo: grupo.value
								}
							},
							placa: placaSolicita.value.replace('-', ''),
							comentario: comentarioSolicita.value,
							id_usuario: vendedorResponsavelId.value == 0 ? undefined : vendedorResponsavelId.value,
							cliente_nome: nomeClienteSolicita.value,
							cliente_telefone: telefoneClienteSolicita.value,
							cliente_email: emailClienteSolicita.value,
							documento_exercicio: documentExerciseSolicita.value,
							garantia_valida: garantiaFabricaCliente.value == 'Sim' ? 1 : 0,
							garantia_data: garantiaFabricaDataCliente.value == undefined ? null : garantiaFabricaDataCliente.value,
							revisao_feita: revisaoFabricaCliente.value == 'Sim' ? 1 : 0,
							revisao_data: revisaoFabricaDataCliente.value == undefined ? null : revisaoFabricaDataCliente.value,
							revisao_km: revisaoFabricaKm.value.replace('.', '').replace('km', '').replace(' ', ''),
							chave_reserva: chaveReservaCliente.value == 'Sim' ? 1 : 0,
							manual: manualCliente.value == 'Sim' ? 1 : 0,
							tipo_negociacao: tipoNegociacaoCliente.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Sucesso',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									clickedSolicitaAvaliacao.value = false;
									requestEvalution.$reset();
									setTimeout(() => {
										loadLists.value = true;
									}, 400);
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
						clickedSolicitaAvaliacao.value = false;
					});
			}
		}
		if (action == 'aceitarRenegociacao') {
			if (modalComentarioRenegociacao.value == true) {
				if (clickedAceitaRenegociacao.value == false && clickedRecusaRenegociacao.value == false) {
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					clickedAceitaRenegociacao.value = true;
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/renegociacoes/aceitaRenegociacao`,
							{
								id_avaliacao: id_avaliacao.value,
								usuario: {
									id_usuario: idUsuario.value,
									nome: nome.value,
									sobrenome: sobrenome.value,
									grupo: {
										id_grupo: id_grupo.value,
										grupo: grupo.value
									},
									equipe: {
										id_equipe: id_equipe.value,
										equipe: equipe.value
									},
									empresa: {
										id_empresa: id_empresa.value,
										empresa: empresa.value
									}
								},
								acessos: acessos.value,
								comentario: comentarioRenegociacao.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Sucesso',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										clickedAceitaRenegociacao.value = true;
										modalComentarioRenegociacao.value = false;
										router.push({ name: 'avaliacoes' });
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
							clickedAceitaRenegociacao.value = false;
						});
				}
			} else {
				typeComentario.value = 'aceitaRenegociacao';
				modalComentarioRenegociacao.value = true;
			}
		}
	}
	if (type == 'deactive') {
		// console.log('aq deve lançar os erros para o store');
	}
	if (type == 'active') {
		if (action == 'login') {
			if (clickButton.value == false) {
				clickButton.value = true;
				if (password.value.length >= 6) {
					axios
						.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/loginUsuario`, {
							email: login.value,
							senha: password.value
						})
						.then((response) => {
							var data = new Date();
							data.setTime(data.getTime() + 24 * 60 * 60 * 1000);
							data.setUTCHours(data.getHours());
							data.setUTCMinutes(data.getMinutes());
							data.setUTCSeconds(data.getSeconds());
							var expires = 'expires=' + data.toUTCString();
							document.cookie = `token_user=${response.data.token}; ` + expires + '; path=/';
							const cookies = document.cookie.split(';');
							const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
							const tokenUser = cookies[tokenIndex].split('=')[1];
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_USUARIO}/decodificarHeader`,
									{},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then((res) => {
									axios
										.post(
											`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/infoUsuario`,
											{
												id_usuario: res.data.dados.usuario.id_usuario
											},
											{
												headers: {
													Authorization: 'Bearer ' + tokenUser,
													'Content-Type': 'application/json'
												}
											}
										)
										.then(async (res) => {
											const registrations = await navigator.serviceWorker.getRegistrations();

											for (const registration of registrations) {
												if (registration.active?.scriptURL === `${import.meta.env.VITE_SERVER}/firebase-messaging-sw.js`) {
													await registration.unregister();
													console.log('Service worker removido com sucesso.');
													break;
												}
											}
											idUsuario.value = res.data.dados.id_usuario;
											nome.value = res.data.dados.nome;
											sobrenome.value = res.data.dados.sobrenome;
											acessos.value = res.data.dados.usuarios_acessos;
											email.value = res.data.dados.email;
											cargo.value = res.data.dados.cargo.cargo;
											grupo.value = res.data.dados.grupo.grupo;
											equipe.value = res.data.dados.grupo.equipe.equipe;
											empresa.value = res.data.dados.grupo.equipe.empresa.empresa;
											id_cargo.value = res.data.dados.id_cargo;
											id_equipe.value = res.data.dados.grupo.id_equipe;
											id_grupo.value = res.data.dados.id_grupo;
											id_empresa.value = res.data.dados.grupo.equipe.empresa.id_empresa;
											infosSearch.value = true;
											toast.success(
												{
													component: toastText,
													props: {
														h1Text: `Bem vindo, ${nome.value}!`,
														pText: 'Login efetuado com sucesso'
													}
												},
												{
													timeout: 2500,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: sucessoIcon,
													rtl: false,
													onClose() {
														router.push({ name: 'inicio' });
													}
												}
											);
											setTimeout(() => {
												clickButton.value = false;
												loginAction.value = false;
											}, 1500);
										});
								})
								.catch((err) => {
									console.log(err);
								});
						})
						.catch((error) => {
							haveAError.value = true;
							if (error.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButton.value = false;
									loginAction.value = false;
								}, 1500);
							} else {
								toast.warning(
									{
										component: toastText,
										props: {
											h1Text: 'Tente novamente',
											pText: error.response.data.mensagem
										}
									},
									{
										timeout: 3500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: avisoIcon,
										rtl: false
									}
								);
								errorLogin.value = true;
								errorText.value = error.response.data.mensagem;
								setTimeout(() => {
									clickButton.value = false;
									loginAction.value = false;
								}, 1500);
							}
						});
				} else {
					errorLogin.value = true;
					errorText.value = 'Tamanho minimo de senha 6 digítos';
					setTimeout(() => {
						clickButton.value = false;
						loginAction.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'forgotPassword') {
			if (clickButtonForgot.value == false) {
				clickButtonForgot.value = true;
				var re = /\S+@\S+\.\S+/;
				if (re.test(emailForgot.value)) {
					axios
						.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/esqueciMinhaSenha`, {
							email: emailForgot.value
						})
						.then((res) => {
							clickButtonForgot.value = false;
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: res.data.mensagem,
										pText: 'voltando ao login'
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										router.push('/');
										forgotPassword.$reset();
									}
								}
							);
						})
						.catch((err) => {
							errorForgot.value = true;
							errorDesc.value = err.response.data.mensagem;
							setTimeout(() => {
								clickButtonForgot.value = false;
							}, 1500);
						});
				} else {
					haveAErrorForgotPassword.value = true;
					errorForgot.value = true;
					errorDesc.value = 'E-mail inválido';
					setTimeout(() => {
						clickButtonForgot.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'saveNewPassword') {
			if (clickButtonChangePassword.value == false) {
				clickButtonChangePassword.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/trocaSenha`,
						{
							email: email.value,
							senha: senhaAntiga.value.length > 0 ? senhaAntiga.value : null,
							senha_nova: novaSenha.value.length > 0 ? novaSenha.value : null,
							confirma_senha: confirmSenha.value.length > 0 ? confirmSenha.value : null
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: res.data.mensagem,
									pText: 'voltando ao início'
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									router.back();
									changePassword.$reset();
								}
							}
						);
					})
					.catch((err) => {
						haveAErrorChangePassword.value = true;
						if (err.message == 'Network Error') {
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Serviço indisponível',
										pText: 'serviço passando por instabilidade'
									}
								},
								{
									timeout: 5000,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
							setTimeout(() => {
								clickButton.value = false;
								loginAction.value = false;
							}, 1500);
						} else {
							errorChangePassword.value = true;
							errorTextChangePassword.value = err.response.data.mensagem;
							setTimeout(() => {
								clickButtonChangePassword.value = false;
							}, 1500);
						}
					});
			}
		}
		if (action == 'changePassword') {
			if (clickButtonForgot.value == false) {
				clickButtonForgot.value = true;
				if (newPasswordForgot.value.length >= 6) {
					axios
						.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/resetarMinhaSenha`, {
							email: emailForgot.value,
							token_recuperacao_senha: passwordTokenForgot.value,
							senha_nova: newPasswordForgot.value,
							confirma_senha: confirmPasswordForgot.value
						})
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: res.data.mensagem,
										pText: 'voltando ao início'
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										router.push('/');
										forgotPassword.$reset();
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
							haveAErrorForgotPassword.value = true;
							haveAErrorUserControl.value = true;
							if (err.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonUserControl.value = false;
								}, 1500);
							} else {
								errorForgot.value = true;
								errorDesc.value = err.response.data;
								setTimeout(() => {
									clickButtonForgot.value = false;
								}, 1500);
							}
						});
				} else {
					haveAErrorForgotPassword.value = true;
					errorForgot.value = true;
					errorDesc.value = 'Tamanho minimo de senha 6 digítos';
					setTimeout(() => {
						clickButtonForgot.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'addNewUser') {
			if (desktop.value) {
				if (clickButtonUserControl.value == false) {
					clickButtonUserControl.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					if (newUserEmail.value.length > 0) {
						if (newUserName.value.length > 0) {
							if (newUserSurname.value.length > 0) {
								if (newUserJobId.value > 0) {
									if (newUserGroupId.value > 0) {
										axios
											.post(
												`${import.meta.env.VITE_AUTO_ME_USUARIO}/usuarios/cadastraUsuario`,
												{
													nome: newUserName.value,
													sobrenome: newUserSurname.value,
													email: newUserEmail.value,
													senha: newUserPassword.value,
													id_cargo: newUserJobId.value,
													id_grupo: newUserGroupId.value,
													id_empresa: id_empresa.value
												},
												{
													headers: {
														Authorization: 'Bearer ' + tokenUser,
														'Content-Type': 'application/json'
													}
												}
											)
											.then((res) => {
												toast.success(
													{
														component: toastText,
														props: {
															h1Text: 'Novo usuário cadastrado',
															pText: `${res.data.mensagem}`
														}
													},
													{
														timeout: 2500,
														closeOnClick: false,
														pauseOnFocusLoss: false,
														pauseOnHover: false,
														draggable: true,
														draggablePercent: 0.8,
														showCloseButtonOnHover: false,
														hideProgressBar: false,
														closeButton: false,
														icon: sucessoIcon,
														rtl: false,
														onClose() {
															refreshListUsers.value = true;
															setTimeout(() => {
																controlUser.$reset();
															}, 100);
														}
													}
												);
												setTimeout(() => {
													clickButtonUserControl.value = false;
												}, 1500);
											})
											.catch((err) => {
												haveAErrorUserControl.value = true;
												if (err.message == 'Network Error') {
													toast.error(
														{
															component: toastText,
															props: {
																h1Text: 'Serviço indisponível',
																pText: 'serviço passando por instabilidade'
															}
														},
														{
															timeout: 5000,
															closeOnClick: false,
															pauseOnFocusLoss: false,
															pauseOnHover: false,
															draggable: true,
															draggablePercent: 0.8,
															showCloseButtonOnHover: false,
															hideProgressBar: false,
															closeButton: false,
															icon: insucessoIcon,
															rtl: false
														}
													);
													setTimeout(() => {
														clickButtonUserControl.value = false;
													}, 1500);
												} else {
													errorUserControl.value = true;
													errorTextUserControl.value = err.response.data.mensagem;
													setTimeout(() => {
														clickButtonUserControl.value = false;
													}, 1500);
												}
											});
									} else {
										errorUserControl.value = true;
										errorTextUserControl.value = 'Grupo não informado';
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									}
								} else {
									errorUserControl.value = true;
									errorTextUserControl.value = 'Cargo não informado';
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								}
							} else {
								errorUserControl.value = true;
								errorTextUserControl.value = 'Sobrenome não informado';
								setTimeout(() => {
									clickButtonUserControl.value = false;
								}, 1500);
							}
						} else {
							errorUserControl.value = true;
							errorTextUserControl.value = 'Nome não informado';
							setTimeout(() => {
								clickButtonUserControl.value = false;
							}, 1500);
						}
					} else {
						errorUserControl.value = true;
						errorTextUserControl.value = 'E-mail não informado';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					}
				}
			} else {
				modalAddUserOpen.value = true;
			}
		}
		if (action == 'editUser') {
			if (clickButtonUserControl.value == false) {
				clickButtonUserControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (editUserName.value.length > 0) {
					if (editUserSurname.value.length > 0) {
						if (editUserGroupId.value > 0) {
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/editaUsuario`,
									{
										email: editUserEmail.value,
										nome: editUserName.value,
										sobrenome: editUserSurname.value,
										id_cargo: editUserJobId.value,
										id_grupo: editUserGroupId.value,
										id_equipe: editUserTeamId.value,
										id_empresa: id_empresa.value
									},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then((res) => {
									toast.success(
										{
											component: toastText,
											props: {
												h1Text: 'Alterações salvas',
												pText: res.data.mensagem
											}
										},
										{
											timeout: 2500,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: sucessoIcon,
											rtl: false,
											onClose() {
												refreshListUsers.value = true;
												setTimeout(() => {
													controlUser.$reset();
												}, 100);
											}
										}
									);
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								})
								.catch((err) => {
									haveAErrorUserControl.value = true;
									if (err.message == 'Network Error') {
										toast.error(
											{
												component: toastText,
												props: {
													h1Text: 'Serviço indisponível',
													pText: 'serviço passando por instabilidade'
												}
											},
											{
												timeout: 5000,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: insucessoIcon,
												rtl: false
											}
										);
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									} else {
										errorUserControl.value = true;
										errorTextUserControl.value = err.response.data;
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									}
								});
						} else {
							errorUserControl.value = true;
							errorTextUserControl.value = 'Grupo não informado';
							setTimeout(() => {
								clickButtonUserControl.value = false;
							}, 1500);
						}
					} else {
						errorUserControl.value = true;
						errorTextUserControl.value = 'Sobrenome não informado';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					}
				} else {
					errorUserControl.value = true;
					errorTextUserControl.value = 'Nome não informado';
					setTimeout(() => {
						clickButtonUserControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'editGroup') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (editGroupName.value.length > 0) {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/grupos/editaGrupo`,
							{
								id_grupo: groupId.value,
								grupo: editGroupName.value,
								id_equipe: teamId.value,
								uf_alvo: editGroupUf.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Alterações salvas',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										refreshListGroups.value = true;
										setTimeout(() => {
											controlGroupTeam.$reset();
										}, 100);
									}
								}
							);
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						})
						.catch((err) => {
							errorGroupTeamControl.value = false;
							errorTextGroupTeamControl.value = '';
							haveAErrorGroupTeamControl.value = true;
							if (err.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else {
								errorGroupTeamControl.value = true;
								errorTextGroupTeamControl.value = err.response.data.mensagem;

								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Ops, algo deu errado',
											pText: err.response.data.mensagem
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);

								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							}
						});
				} else {
					errorGroupTeamControl.value = true;
					errorTextGroupTeamControl.value = 'Nome do grupo não informado';
					setTimeout(() => {
						clickButtonGroupTeamControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'editTeam') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (editTeamName.value.length > 0) {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/equipes/editaEquipe`,
							{
								equipe: editTeamName.value,
								id_equipe: teamId.value,
								id_empresa: id_empresa.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Alterações salvas',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										refreshListGroups.value = true;
										setTimeout(() => {
											controlGroupTeam.$reset();
										}, 100);
									}
								}
							);
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						})
						.catch((err) => {
							haveAErrorGroupTeamControl.value = true;
							if (err.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else {
								errorGroupTeamControl.value = true;
								errorTextGroupTeamControl.value = err.response.data.mensagem;
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							}
						});
				} else {
					errorGroupTeamControl.value = true;
					errorTextGroupTeamControl.value = 'Nome da equipe não informado';
					setTimeout(() => {
						clickButtonGroupTeamControl.value = false;
					}, 1500);
				}
			}
		}
		if (action == 'addNewGroup') {
			if (desktop.value) {
				if (clickButtonGroupTeamControl.value == false) {
					clickButtonGroupTeamControl.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					if (newGroupName.value.length > 0) {
						if (newTeamGroupId.value > 0) {
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/grupos/cadastraGrupo`,
									{
										grupo: newGroupName.value,
										// id_grupo: groupId.value,
										id_equipe: newTeamGroupId.value,
										uf_alvo: newGroupUf.value
									},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then((res) => {
									toast.success(
										{
											component: toastText,
											props: {
												h1Text: 'Novo grupo cadastrado',
												pText: 'Grupo cadastrado com sucesso'
											}
										},
										{
											timeout: 2500,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: sucessoIcon,
											rtl: false,
											onClose() {
												refreshListGroups.value = true;
												setTimeout(() => {
													controlGroupTeam.$reset();
												}, 100);
											}
										}
									);
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								})
								.catch((err) => {
									haveAErrorGroupTeamControl.value = true;
									if (err.message == 'Network Error') {
										toast.error(
											{
												component: toastText,
												props: {
													h1Text: 'Serviço indisponível',
													pText: 'serviço passando por instabilidade'
												}
											},
											{
												timeout: 5000,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: insucessoIcon,
												rtl: false
											}
										);
										setTimeout(() => {
											clickButtonGroupTeamControl.value = false;
										}, 1500);
									} else {
										if (err.response.data.mensagem == 'Nome de grupo já cadastrado nesta equipe ou empresa') {
											toast.warning(
												{
													component: toastText,
													props: {
														h1Text: err.response.data.mensagem,
														pText: 'Altere o nome ou escolha outra equipe'
													}
												},
												{
													timeout: 2500,
													closeOnClick: false,
													pauseOnFocusLoss: false,
													pauseOnHover: false,
													draggable: true,
													draggablePercent: 0.8,
													showCloseButtonOnHover: false,
													hideProgressBar: false,
													closeButton: false,
													icon: avisoIcon,
													rtl: false
												}
											);
											setTimeout(() => {
												clickButtonGroupTeamControl.value = false;
											}, 1500);
										} else {
											errorGroupTeamControl.value = true;
											errorTextGroupTeamControl.value = err.response.data;
											setTimeout(() => {
												clickButtonGroupTeamControl.value = false;
											}, 1500);
										}
									}
								});
						} else {
							errorGroupTeamControl.value = true;
							errorTextGroupTeamControl.value = 'Equipe não informada';
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						}
					} else {
						errorGroupTeamControl.value = true;
						errorTextGroupTeamControl.value = 'Nome do grupo não informado';
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					}
				}
			} else {
				modalAddGroup.value = true;
			}
		}
		if (action == 'evaluationNext') {
			if (currentStep.value == 1) {
				if (tipoAvaliacao.value == 1) {
					currentStep.value = 3;
				} else {
					currentStep.value++;
				}
			} else {
				currentStep.value++;
			}
		}
		if (action == 'sendEvaluation') {
			const cookies = document.cookie.split(';');
			const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
			const tokenUser = cookies[tokenIndex].split('=')[1];
			if (clickedSendEvaluation.value == false) {
				clickedSendEvaluation.value = true;
				respostasAvaliacao.value.forEach((respostas: any) => {
					if (typeof respostas.valor !== 'number') {
						const valor_processado = parseInt(respostas.valor.replace('R$', '').replace(/\./g, ''));
						respostas.valor = isNaN(valor_processado) ? 0 : valor_processado;
					}
					respostas.fotos.forEach((fotos: any) => {
						delete fotos.fotoInput;
					});
				});
				let object = {
					id_solicitacao: idSolicitacao.value == '' ? null : idSolicitacao.value,
					convidado: convite.value == true ? 1 : 0,
					cliente_nome: nomeClienteSolicita.value,
					cliente_telefone: telefoneClienteSolicita.value,
					cliente_email: emailClienteSolicita.value,
					usuario: {
						id_usuario: idUsuario.value,
						nome: nome.value,
						sobrenome: sobrenome.value,
						grupo: {
							id_grupo: id_grupo.value,
							grupo: grupo.value
						},
						cargo: {
							id_cargo: id_cargo.value,
							cargo: cargo.value
						},
						equipe: {
							id_equipe: id_equipe.value,
							equipe: equipe.value
						},
						empresa: {
							id_empresa: id_empresa.value,
							empresa: empresa.value
						}
					},
					veiculo: {
						placa: placa.value,
						renavam: renavam.value,
						veiculo_tipo: veiculo_tipo.value.veiculo_tipo,
						ano_fabricacao: anoFabricacao.value,
						fipe: {
							id_fipe: id_fipe.value,
							codigo_fipe: codigo_fipe.value,
							ano_modelo: anoModelo.value,
							marca: marca.value,
							modelo: modelo.value,
							versao: versao.value,
							fipes_historico_lista: historicosFipe.value,
							eletrico: eletricoAval.value !== undefined ? eletricoAval.value : false
						},
						cor: {
							id_cor: idCor.value,
							cor: cor.value
						},
						combustivel: combustivel.value,
						teto_solar: tetoSolar.value,
						blindagem: blindagem.value
					},
					avaliacao: {
						avaliacao_tipo: tipoAvaliacao.value,
						data_criacao: new Date(),
						km: parseInt(km.value.replace('km', '').replace(/\./g, '').replace(/\,/g, '')),
						chave_reserva: chaveReserva.value == 'Sim' ? true : false,
						manual: manual.value == 'Sim' ? true : false,
						documento_exercicio: documentExercise.value,
						garantia: {
							garantia_valida: garantiaFabrica.value == 'Sim' ? true : false,
							garantia_data: garantiaData.value
						},
						revisao: {
							revisao_feita: revisao.value == 'Sim' ? true : false,
							revisao_data: revisaoData.value,
							revisao_km: parseInt(kmRevisao.value.replace('km', '').replace(/\./g, ''))
						},
						csvs: registrosCsvs.value,
						fotos: fotosAvaliacao.value,
						reparos: respostasAvaliacao.value,
						higienizacao: respostasHigienizacaoPolimento.value.includes('Higienizacao'),
						polimento: respostasHigienizacaoPolimento.value.includes('Polimento'),
						tipo_negociacao: tipoNegociacao.value,
						...(outrosCustosValor.value && outrosCustosDescricao.value
							? {
									outros_custos: {
										valor: parseInt(outrosCustosValor.value.toString().replace(/\./g, '')),
										descricao: outrosCustosDescricao.value
									}
							  }
							: {})
					}
				};

				let autoPayPrec = 0;
				let idAvaliacaoNew = 0;

				axios
					.post(`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/novaAvaliacao`, object, {
						headers: {
							Authorization: 'Bearer ' + tokenUser,
							'Content-Type': 'application/json'
						}
					})
					.then((res) => {
						idAvaliacaoNew = res.data.dados.id_avaliacao;
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: `${res.data.mensagem}`,
									pText: 'Voltando ao inicio'
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									setTimeout(() => {
										if (convite.value == true) {
											currentStep.value = 4;
											clickedSendEvaluation.value = false;
											axios.get(`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/utilizaConvite`, {
												headers: {
													token: conviteValue.value,
													'Content-Type': 'application/json'
												}
											});
										} else {
											clickedSendEvaluation.value = false;
											evaluation.$reset();
											vehicleInfos.$reset();
											requestEvalution.$reset();
											router.push({ name: 'inicio' });
										}
									}, 600);
								}
							}
						);
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_PRECIFICACOES}/precificacoes/precificaAvaliacao`,
								{
									// id_empresa: object.usuario.empresa.id_empresa,
									// id_equipe: object.usuario.equipe.id_equipe,
									// id_grupo: object.usuario.grupo.id_grupo,
									id_usuario_requerente: object.usuario.id_usuario,

									placa: object.veiculo.placa,
									veiculo_tipo: object.veiculo.veiculo_tipo,
									id_fipe: object.veiculo.fipe.id_fipe,
									valor_fipe_atual: object.veiculo.fipe.fipes_historico_lista[0].valor,
									variacao_media_fipe: (object.veiculo.fipe.fipes_historico_lista[0].valor - object.veiculo.fipe.fipes_historico_lista.pop().valor) / object.veiculo.fipe.fipes_historico_lista.length,
									ano_fabricacao: object.veiculo.ano_fabricacao,
									documento_exercicio: object.avaliacao.documento_exercicio.toString(),
									km: object.avaliacao.km,
									teto_solar: object.veiculo.teto_solar == true ? 1 : 0,
									blindagem: object.veiculo.blindagem == true ? 1 : 0,
									higienizacao: object.avaliacao.higienizacao ? 1 : 0,
									polimento: object.avaliacao.polimento ? 1 : 0,
									anuncios_ignorar: [],
									ignorar_teto_solar: 0,
									eletrico: eletricoAval.value !== undefined ? eletricoAval.value : false,
									revisao_feita: object.avaliacao.revisao.revisao_feita == true ? 1 : 0,
									reparos: object.avaliacao.reparos.reduce((soma: any, pergunta: any) => {
										return soma + pergunta.valor;
									}, 0),
									outros_custos: object.avaliacao.outros_custos || 0
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								autoPayPrec = res.data.dados.auto_pay;
								const decidirDestino = () => {
									let primeiroDestino = res.data.dados.destino.destino;
									let indiceDestino = res.data.dados.avaliacoes.findIndex((avaliacao: any) => avaliacao.destino.destino === primeiroDestino);
									return indiceDestino;
								};
								if (autoPayPrec == 1) {
									axios.post(
										`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/pagaAvaliacao`,
										{
											id_avaliacao: idAvaliacaoNew,
											preco_mesa: res.data.dados.avaliacoes[decidirDestino()].valor_sugerido,
											comentario: 'Pago com auxílio do AutoPay',
											usuario: {
												id_usuario: object.usuario.id_usuario,
												nome: object.usuario.nome,
												sobrenome: object.usuario.sobrenome
											},
											avaliacao: {
												auto_pay: autoPayPrec,
												precisao: res.data.dados.precisao,
												internet: res.data.dados.internet,
												veiculo_tipos: {
													veiculo_tipo: res.data.dados.veiculo_tipos.veiculo_tipo
												},
												metais: {
													metal: res.data.dados.metais.metal
												},
												avaliacoes: [
													{
														destino: {
															id_destino: res.data.dados.avaliacoes[decidirDestino()].destino.id_destino,
															destino: res.data.dados.avaliacoes[decidirDestino()].destino.destino
														},
														praca: res.data.dados.avaliacoes[decidirDestino()].praca,
														media_internet: res.data.dados.avaliacoes[decidirDestino()].media_internet,
														margem_negociacao: res.data.dados.avaliacoes[decidirDestino()].margem_negociacao,
														lucro: res.data.dados.avaliacoes[decidirDestino()].lucro,
														reparos: res.data.dados.avaliacoes[decidirDestino()].reparos,
														higienizacao_estetica: res.data.dados.avaliacoes[decidirDestino()].higienizacao_estetica,
														depreciacao_capital: res.data.dados.avaliacoes[decidirDestino()].depreciacao_capital,
														depreciacao_fipe: res.data.dados.avaliacoes[decidirDestino()].depreciacao_fipe,
														ipva: res.data.dados.avaliacoes[decidirDestino()].ipva,
														provisao_revisoes: res.data.dados.avaliacoes[decidirDestino()].provisao_revisoes,
														icms: res.data.dados.avaliacoes[decidirDestino()].icms,
														pis_cofins: res.data.dados.avaliacoes[decidirDestino()].pis_cofins,
														valor_sugerido: res.data.dados.avaliacoes[decidirDestino()].valor_sugerido,
														lucro_repassador: res.data.dados.avaliacoes[decidirDestino()].lucro_repassador,
														outros_custos: res.data.dados.avaliacoes[decidirDestino()].outros_custos
													}
												]
											}
										},
										{
											headers: {
												Authorization: 'Bearer ' + tokenUser,
												'Content-Type': 'application/json'
											}
										}
									);
								}
							});
					})
					.catch((err: any) => {
						if (err.response.status == 401) {
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Aviso',
										pText: err.response.data.mensagem
									}
								},
								{
									timeout: 3500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
						} else {
							toast.warning(
								{
									component: toastText,
									props: {
										h1Text: 'Tente novamente',
										pText: err.response.data.mensagem
									}
								},
								{
									timeout: 3500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: avisoIcon,
									rtl: false
								}
							);
						}
						clickedSendEvaluation.value = false;
					});
			}
		}
		if (action == 'saveChangesAcess') {
			if (desktop.value) {
				if (clickedAcess.value == false) {
					clickedAcess.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/editaAcessosUsuario`,
							{
								id_usuario: userId.value,
								acessos: acessosControl.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							refreshListAcess.value = true;
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Alterações salvas',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										setTimeout(() => {
											clickedAcess.value = false;
										}, 600);
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
						});
				}
			} else {
				if (clickedAcess.value == false) {
					clickedAcess.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/editaAcessosUsuario`,
							{
								id_usuario: userId.value,
								acessos: acessosControl.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Alterações salvas',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										setTimeout(() => {
											clickedAcess.value = false;
											refreshListAcess.value = true;
										}, 600);
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
						});
				}
			}
		}
		if (action == 'saveChangesRulesDestination') {
			if (clickedSaveChangesDestination.value == false) {
				clickedSaveChangesDestination.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];

				if (parseInt(kmMaximaRepasse.value) > 0 && parseInt(kmMaximaRepasse.value) < 1000000) {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/precificacoes/editaRegraDestino`,
							{
								id_empresa: id_empresa.value,
								id_veiculo_tipo: tipoVeiculo.value,
								id_tipo_veiculo: veiculoTipo.value,
								eletrico: eletrico.value,
								id_metal: metal.value,
								km_maxima: kmMaximaRepasse.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Alterações salvas',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										setTimeout(() => {
											// tipoVeiculo.value = 2;
											// metal.value = 1;
											// veiculoTipo.value = 1;
											// eletrico.value = false;
											haveAErrorRulesDestination.value = false;
											errorRulesDestination.value = false;
											errorTextRulesDestination.value = '';
											clickedSaveChangesDestination.value = false;
										}, 600);
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);

							// toast.error(
							// 	{
							// 		component: toastText,
							// 		props: {
							// 			h1Text: 'Ops! Tente novamente',
							// 			pText: 'Algo deu errado, por favor, tente novamente em alguns instantes!'
							// 		}
							// 	},
							// 	{
							// 		timeout: 2500,
							// 		closeOnClick: false,
							// 		pauseOnFocusLoss: false,
							// 		pauseOnHover: false,
							// 		draggable: true,
							// 		draggablePercent: 0.8,
							// 		showCloseButtonOnHover: false,
							// 		hideProgressBar: false,
							// 		closeButton: false,
							// 		icon: insucessoIcon,
							// 		rtl: false,
							// 		onClose() {
							// 			setTimeout(() => {
							// 				clickedSaveChangesDestination.value = false;
							// 			}, 600);
							// 		}
							// 	}
							// );
						});
				} else {
					haveAErrorRulesDestination.value = true;
					errorRulesDestination.value = true;
					errorTextRulesDestination.value = 'Km máxima tem que ser maior que 0 e menor que 1 milhão';
					setTimeout(() => {
						clickedSaveChangesDestination.value = false;
					}, 600);
				}
			}
		}
		if (action == 'saveChangesRulesAutoPay') {
			if (clickedAutoPay.value == false) {
				clickedAutoPay.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/autopay/editaRegras`,
						{
							id_empresa: id_empresa.value,
							empresa: empresa.value,
							equipes: regras.value.equipes,
							id_usuario_modificacao: idUsuario.value,
							nome_usuario_modificacao: nome.value + ' ' + sobrenome.value,
							regras: regras.value.regras
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Alterações salvas',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									setTimeout(() => {
										tipoVeiculoAutoPay.value = 2;
										classificacaoMetalAutoPay.value = 1;
										clickedAutoPay.value = false;
										reload.value = true;
									}, 600);
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'saveChangesRulesPricing') {
			const formatValue = (value: any) => {
				return value.replace(',', '.').replace(/[%R$]/g, '').trim();
			};
			const formatDays = (value: any) => {
				return value.replace('dias', '').trim();
			};
			const formatPercentage = (value: any) => {
				const floatValue = parseFloat(value);
				return Number.isInteger(floatValue) ? floatValue.toFixed(0) : floatValue.toFixed(2);
			};
			if (clickedSaveChangesPricing.value == false) {
				clickedSaveChangesPricing.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/precificacoes/editaRegraPrecificacao`,
						{
							// uf_alvo: uf.value,
							regras_precificacao: {
								id_regra: idRegraPrecificacao.value,
								id_empresa: id_empresa.value,
								id_veiculo_tipo: tipoVeiculo.value,
								id_destino: tipoPrecificacao.value,
								id_metal: metal.value,
								margem_percentual: parseFloat(formatPercentage(formatValue(margemNegociacao.value))) / 100,
								margem_minima: formatValue(margemMinima.value) == '' ? null : parseInt(formatValue(margemMinima.value).replace('.', '')),
								margem_maxima: formatValue(margemMaxima.value) == '' ? null : parseInt(formatValue(margemMaxima.value).replace('.', '')),
								lucro_percentual: parseFloat(formatPercentage(formatValue(lucro.value))) / 100,
								lucro_minimo: formatValue(lucroMinimo.value) == '' ? null : parseInt(formatValue(lucroMinimo.value).replace('.', '')),
								lucro_maximo: formatValue(lucroMaximo.value) == '' ? null : parseInt(formatValue(lucroMaximo.value).replace('.', '')),
								higienizacao: formatValue(higienizacaoCusto.value) == '' ? 0 : parseInt(formatValue(higienizacaoCusto.value).replace('.', '')),
								polimento: formatValue(polimentoCusto.value) == '' ? 0 : parseInt(formatValue(polimentoCusto.value).replace('.', '')),
								depreciacao_fipe: depreciacaoFipe.value,
								depreciacao_capital: parseFloat(formatPercentage(formatValue(depreciacaoCapitalValue.value))) / 100,
								depreciacao_capital_carencia: formatDays(carenciaFloorplan.value),
								revisoes_percentual: parseFloat(formatPercentage(formatValue(percentualFipeCustosRevisao.value))) / 100,
								ipva: formatValue(aliquotaIpva.value) == '' ? null : parseFloat(formatPercentage(formatValue(aliquotaIpva.value))) / 100,
								icms: formatValue(aliquotaIcms.value) == '' ? null : parseFloat(formatPercentage(formatValue(aliquotaIcms.value))) / 100,
								pis_cofins: formatValue(aliquotaPisCofins.value) == '' ? null : parseFloat(formatPercentage(formatValue(aliquotaPisCofins.value))) / 100,
								tempovenda_maximo: formatDays(tempoEstoqueMaximo.value) == '' ? null : formatDays(tempoEstoqueMaximo.value),
								tempovenda_minimo: formatDays(tempoEstoqueMinimo.value) == '' ? null : formatDays(tempoEstoqueMinimo.value),
								dias_para_reparo: formatDays(acrescimoParaReparo.value) == '' ? null : formatDays(acrescimoParaReparo.value),
								lucro_repassador: formatValue(lucroRepassador.value) == '' ? 0 : parseFloat(formatPercentage(formatValue(lucroRepassador.value))) / 100,
								lucro_repassador_minimo: formatValue(lucroRepassadorMinimo.value) == '' ? null : parseInt(formatValue(lucroRepassadorMinimo.value).replace('.', '')),
								lucro_repassador_maximo: formatValue(lucroRepassadorMaximo.value) == '' ? null : parseInt(formatValue(lucroRepassadorMaximo.value).replace('.', ''))
							}
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Alterações salvas com sucesso',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									setTimeout(() => {
										clickedSaveChangesPricing.value = false;
									}, 600);
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'payEvaluation') {
			const cookies = document.cookie.split(';');
			const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
			const tokenUser = cookies[tokenIndex].split('=')[1];
			if (clickedButtonPagaAvaliacao.value == false) {
				clickedButtonPagaAvaliacao.value = true;
				if (Number(preco.value.toString().replace('R$', '').replace(' ', '').replace(/\./g, '')) > 0) {
					precificacao.value.internet.internet_tempo_anuncios_removidos_var = precificacao.value.internet.internet_tempo_anuncios_removidos_var == null ? 0 : precificacao.value.internet.internet_tempo_anuncios_removidos_var;
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/pagaAvaliacao`,
							{
								id_avaliacao: id_avaliacao.value,
								preco_mesa: Number(preco.value.toString().replace('R$', '').replace(' ', '').replace(/\./g, '')),
								comentario: comentario.value,
								usuario: {
									id_usuario: idUsuario.value,
									nome: nome.value,
									sobrenome: sobrenome.value
								},
								avaliacao: {
									auto_pay: auto_pay.value,
									precisao: precificacao.value.precisao,
									internet: precificacao.value.internet,
									veiculo_tipos: {
										veiculo_tipo: veiculo_tipos.value.veiculo_tipo
									},
									metais: {
										metal: precificacao.value.metais.metal
									},
									avaliacoes: [
										{
											destino: {
												id_destino: precificacao.value.avaliacoes[avaliacaoEscolhida.value].destino.id_destino,
												destino: precificacao.value.avaliacoes[avaliacaoEscolhida.value].destino.destino
											},
											praca: precificacao.value.avaliacoes[avaliacaoEscolhida.value].praca,
											media_internet: precificacao.value.avaliacoes[avaliacaoEscolhida.value].media_internet,
											margem_negociacao: precificacao.value.avaliacoes[avaliacaoEscolhida.value].margem_negociacao,
											lucro: precificacao.value.avaliacoes[avaliacaoEscolhida.value].lucro,
											reparos: precificacao.value.avaliacoes[avaliacaoEscolhida.value].reparos,
											higienizacao_estetica: precificacao.value.avaliacoes[avaliacaoEscolhida.value].higienizacao_estetica,
											depreciacao_capital: precificacao.value.avaliacoes[avaliacaoEscolhida.value].depreciacao_capital,
											depreciacao_fipe: precificacao.value.avaliacoes[avaliacaoEscolhida.value].depreciacao_fipe,
											ipva: precificacao.value.avaliacoes[avaliacaoEscolhida.value].ipva,
											provisao_revisoes: precificacao.value.avaliacoes[avaliacaoEscolhida.value].provisao_revisoes,
											icms: precificacao.value.avaliacoes[avaliacaoEscolhida.value].icms,
											pis_cofins: precificacao.value.avaliacoes[avaliacaoEscolhida.value].pis_cofins,
											valor_sugerido: precificacao.value.avaliacoes[avaliacaoEscolhida.value].valor_sugerido,
											lucro_repassador: precificacao.value.avaliacoes[avaliacaoEscolhida.value].lucro_repassador,
											outros_custos: precificacao.value.avaliacoes[avaliacaoEscolhida.value].outros_custos
										}
									]
								}
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							listAval();

							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Pagamento concluído',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										// evaluationView.$reset();
										clickedButtonPagaAvaliacao.value = false;
										router.push({ name: 'avaliacoes' });
									}
								}
							);
						})
						.catch((err) => {
							toast.warning(
								{
									component: toastText,
									props: {
										h1Text: 'Tente novamente',
										pText: err.response.data.mensagem
									}
								},
								{
									timeout: 3500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: avisoIcon,
									rtl: false,
									onClose() {
										clickedButtonPagaAvaliacao.value = false;
									}
								}
							);
						});
				} else {
					clickedButtonPagaAvaliacao.value = false;
					errorEvaluationView.value = true;
					errorEvaluationViewText.value = 'Preço da mesa avaliadora deve ser maior que 0';
				}
			}
		}
		if (action == 'requestEvaluation') {
			if (clickedSolicitaAvaliacao.value == false) {
				clickedSolicitaAvaliacao.value = true;
			}
			const cookies = document.cookie.split(';');
			const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
			const tokenUser = cookies[tokenIndex].split('=')[1];
			axios
				.post(
					`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/solicitaAvaliacao`,
					{
						usuario: {
							id_usuario: idUsuario.value,
							nome: nome.value,
							sobrenome: sobrenome.value,
							empresa: {
								id_empresa: id_empresa.value,
								empresa: empresa.value
							},
							equipe: {
								id_equipe: id_equipe.value,
								equipe: equipe.value
							},
							grupo: {
								id_grupo: id_grupo.value,
								grupo: grupo.value
							}
						},
						placa: placaSolicita.value.replace('-', ''),
						comentario: comentarioSolicita.value,
						id_usuario: vendedorResponsavelId.value == 0 ? undefined : vendedorResponsavelId.value,
						cliente_nome: nomeClienteSolicita.value,
						cliente_telefone: telefoneClienteSolicita.value,
						cliente_email: emailClienteSolicita.value,
						documento_exercicio: documentExerciseSolicita.value,
						garantia_valida: garantiaFabricaCliente.value == 'Sim' ? 1 : 0,
						garantia_data: garantiaFabricaDataCliente.value == undefined ? null : garantiaFabricaDataCliente.value,
						revisao_feita: revisaoFabricaCliente.value == 'Sim' ? 1 : 0,
						revisao_data: revisaoFabricaDataCliente.value == undefined ? null : revisaoFabricaDataCliente.value,
						revisao_km: revisaoFabricaKm.value.replace('.', '').replace('km', ''),
						chave_reserva: chaveReservaCliente.value == 'Sim' ? 1 : 0,
						manual: manualCliente.value == 'Sim' ? 1 : 0,
						tipo_negociacao: tipoNegociacaoCliente.value
					},
					{
						headers: {
							Authorization: 'Bearer ' + tokenUser,
							'Content-Type': 'application/json'
						}
					}
				)
				.then((res) => {
					toast.success(
						{
							component: toastText,
							props: {
								h1Text: 'Sucesso',
								pText: res.data.mensagem
							}
						},
						{
							timeout: 2500,
							closeOnClick: false,
							pauseOnFocusLoss: false,
							pauseOnHover: false,
							draggable: true,
							draggablePercent: 0.8,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: false,
							icon: sucessoIcon,
							rtl: false,
							onClose() {
								clickedSolicitaAvaliacao.value = false;
								requestEvalution.$reset();
								setTimeout(() => {
									loadLists.value = true;
								}, 400);
							}
						}
					);
				})
				.catch((err) => {
					console.log(err);
					clickedSolicitaAvaliacao.value = false;
				});
		}
		if (action == 'saveChangesValorFinal') {
			if (clickedButtonEditValorFinal.value == false) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				clickedButtonEditValorFinal.value = true;
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/alteraValorPagoAvaliacao`,
						{
							id_avaliacao: id_avaliacao.value,
							preco_mesa: Number(editValorFinal.value.toString().replace('R$', '').replace(' ', '').replace(/\./g, '')),
							usuario: {
								id_usuario: idUsuario.value,
								nome: nome.value,
								sobrenome: sobrenome.value
							}
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Alteração de valor enviado',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									editValorFinal.value = '';
									clickedButtonEditValorFinal.value = false;
									modalEditaValor.value = false;
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'saveChangesDesvioPagamento') {
			if (clickedButtonDesvioPagamento.value === false) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				clickedButtonDesvioPagamento.value = true;

				const modifiedNotificacoesAtivas = JSON.parse(JSON.stringify(notificacoesAtivas.value));

				const item = modifiedNotificacoesAtivas.find((item: any) => item.id_notificacao_possivel === 4);

				const formatValue = (value: string) => {
					return value.replace(',', '.').replace(/[%R$]/g, '').trim();
				};

				const formatPercentage = (value: string) => {
					const floatValue = parseFloat(value);
					return Number.isInteger(floatValue) ? floatValue.toFixed(0) : floatValue.toFixed(2);
				};

				if (item && typeof item.variavel === 'string' && item.variavel !== null) {
					const parsedValue = parseFloat(formatPercentage(formatValue(item.variavel)));
					item.variavel = (parsedValue / 100).toFixed(2);
				}

				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/notificacoes/editaUsuariosNotificacoes`,
						{
							id_usuario: idUsuario.value,
							notificacoes: modifiedNotificacoesAtivas
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Alterações salvas',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									clickedButtonDesvioPagamento.value = false;
									modalDesvioPagamento.value = false;
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'saveChangesRenegociacao') {
			if (clickedButtonEditRenegociacao.value == false) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				clickedButtonEditRenegociacao.value = true;
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_PRECIFICACOES}/precificacoes/recalculaAvaliacao`,
						{
							regras: {
								id_usuario_requerente: usuario.value.id_usuario,
								id_empresa: id_empresa.value,
								veiculo_tipo: veiculo_tipos.value.veiculo_tipo,
								metal: precificacao.value.metais.metal,
								km: avaliacao.value.km,
								eletrico: eletrico.value
							},
							dias_para_venda: parseInt(valorTempoVendaNovo.value),
							variacao_media_fipe: fipe.value.variacao_ultimos_seis_meses,
							id_fipe: veiculo_avaliado.value.id_fipe,
							avaliacao: {
								destino: { id_destino: precificacao.value.destino.id_destino, destino: precificacao.value.destino.destino },
								praca: precificacao.value.avaliacoes[0].praca,
								media_internet: parseInt(valorMediaInternetNovo.value.toString().replace('.', '').replace('.', '')),
								margem_negociacao: parseInt(valorMargemNegociacaoNovo.value.toString().replace('.', '').replace('.', '')),
								lucro: parseInt(valorLucroNovo.value.toString().replace('.', '').replace('.', '')),
								reparos: parseInt(valorReparosNovo.value.toString().replace('.', '').replace('.', '')),
								higienizacao_estetica: parseInt(valorHigienizacaoEsteticaNovo.value.toString().replace('.', '').replace('.', '')),
								depreciacao_capital: precificacao.value.avaliacoes[0].depreciacao_capital,
								depreciacao_fipe: precificacao.value.avaliacoes[0].depreciacao_fipe,
								ipva: precificacao.value.avaliacoes[0].ipva,
								provisao_revisoes: parseInt(valorProvisaoRevisoesNovo.value.toString().replace('.', '').replace('.', '')),
								icms: precificacao.value.avaliacoes[0].icms,
								pis_cofins: precificacao.value.avaliacoes[0].pis_cofins,
								valor_sugerido: precificacao.value.avaliacoes[0].valor_sugerido,
								outros_custos: parseInt(valorOutrosCustosNovo.value.toString().replace('.', '').replace('.', ''))
							}
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						const propriedadesAtualizar = ['depreciacao_capital', 'depreciacao_fipe', 'ipva', 'icms', 'pis_cofins', 'valor_sugerido'];

						const dadosAvaliacao = res.data.dados.avaliacao;

						Object.keys(dadosAvaliacao).forEach((key) => {
							if (dadosAvaliacao[key] === null) {
								dadosAvaliacao[key] = 0;
							}
						});

						propriedadesAtualizar.forEach((propriedade) => {
							precificacao.value.avaliacoes[indexButton.value][propriedade] = dadosAvaliacao[propriedade];
						});

						clickedButtonEditRenegociacao.value = false;
						modalEditaValor.value = false;
					})
					.catch((err) => {
						clickedButtonEditRenegociacao.value = false;
						console.log(err);
					});
			}
		}
		if (action == 'sendRenegociacao') {
			if (clickedButtonEditRenegociacao.value == false) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				clickedButtonEditRenegociacao.value = true;

				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/renegociacoes/enviaRenegociacao`,
						{
							id_avaliacao: id_avaliacao.value,
							usuario: {
								id_usuario: idUsuario.value,
								nome: nome.value,
								sobrenome: sobrenome.value,
								grupo: {
									id_grupo: id_grupo.value,
									grupo: grupo.value
								},
								equipe: {
									id_equipe: id_equipe.value,
									equipe: equipe.value
								},
								empresa: {
									id_empresa: id_empresa.value,
									empresa: empresa.value
								}
							},
							acessos: acessos.value,
							renegociacao: {
								destino: {
									id_destino: precificacao.value.avaliacoes[indexButton.value].destino.id_destino,
									destino: precificacao.value.avaliacoes[indexButton.value].destino.destino
								},
								media_internet: parseInt(valorMediaInternetNovo.value.toString().replace('.', '')),
								lucro: parseInt(valorLucroNovo.value.toString().replace('.', '')),
								margem_negociacao: parseInt(valorMargemNegociacaoNovo.value.toString().replace('.', '')),
								internet_tempo_medio_anuncio: parseInt(valorTempoVendaNovo.value),
								reparos: parseInt(valorReparosNovo.value.toString().replace('.', '')),
								higienizacao_estetica: parseInt(valorHigienizacaoEsteticaNovo.value.toString().replace('.', '')),
								depreciacao_capital: precificacao.value.avaliacoes[indexButton.value].depreciacao_capital,
								depreciacao_fipe: precificacao.value.avaliacoes[indexButton.value].depreciacao_fipe,
								ipva: precificacao.value.avaliacoes[indexButton.value].ipva,
								provisao_revisoes: parseInt(valorProvisaoRevisoesNovo.value.toString().replace('.', '')),
								icms: precificacao.value.avaliacoes[indexButton.value].icms,
								lucro_repassador: precificacao.value.avaliacoes[indexButton.value].destino.id_destino == 2 ? precificacao.value.avaliacoes[indexButton.value].lucro_repassador : undefined,
								pis_cofins: precificacao.value.avaliacoes[indexButton.value].pis_cofins,
								valor_sugerido: precificacao.value.avaliacoes[indexButton.value].valor_sugerido,
								outros_custos: parseInt(valorOutrosCustosNovo.value.toString().replace('.', ''))
							},
							comentario: comentarioRenegociacao.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Renegociação enviada',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									clickedButtonEditRenegociacao.value = false;
									modalComentarioRenegociacao.value = false;
									router.push({ name: 'avaliacoes' });
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'saveChangesAvaliacoesMensais') {
			if (clickButtonCompanyControl.value == false) {
				clickButtonCompanyControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];

				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/empresas/editaAvaliacoesMensaisEmpresa`,
						{
							id_empresa: companyId.value,
							avaliacoes: editAvaliacoes_mensais.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Alterações salvas',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									setTimeout(() => {
										clickButtonCompanyControl.value = false;
										refreshListCompany.value = true;
										modalEditCompany.value = false;
									}, 600);
								}
							}
						);
					})
					.catch((err) => {
						clickButtonCompanyControl.value = false;
						console.log(err);
					});
			}
		}
		if (action == 'newEvaluation') {
			if (mobile.value) {
				router.push({ name: 'avaliacao' });
			} else {
				router.push({ name: 'avaliacao' }).then(() => {
					tipoAvaliacao.value = 1;
					currentStep.value = 1;
				});
			}
		}
	}
	if (type == 'activeUnfill') {
		if (action == 'goToInicio') {
			router.push('/');
		}
		if (action == 'resetAcess') {
			if (clickedResetAcess.value == false) {
				clickedResetAcess.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/voltaAcessosPadraoUsuario`,
						{
							id_usuario: userId.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						clickedResetAcess.value = false;
						setTimeout(() => {
							acessosControl.value = res.data.dados;
							let visualizacaoAvaliacoes = [100, 101, 102];
							let precificacaoAvaliacoes = [201, 202, 203];
							let solicitacaoAvaliacoes = [300, 301, 302];
							atualizarAtivos(visualizacaoAvaliacoes);
							atualizarAtivos(precificacaoAvaliacoes);
							atualizarAtivos(solicitacaoAvaliacoes);
						}, 200);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
		if (action == 'resetRulesDestination') {
			if (clickedResetRulesDestination.value == false) {
				clickedResetRulesDestination.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];

				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/precificacoes/voltaRegraDestinoPadrao`,
						{
							id_empresa: id_empresa.value,
							id_veiculo_tipo: tipoVeiculo.value,
							id_tipo_veiculo: veiculoTipo.value,
							eletrico: eletrico.value,
							id_metal: metal.value,
							km_maxima: kmMaximaRepasse.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						setTimeout(() => {
							kmMaximaRepasse.value = res.data.dados.km_maxima;
							tipoVeiculo.value = res.data.dados.id_veiculo_tipo;
							veiculoTipo.value = res.data.dados.id_tipo_veiculo;
							eletrico.value = res.data.dados.eletrico;
							metal.value = res.data.dados.id_metal;
							clickedResetRulesDestination.value = false;
						}, 250);
					})
					.catch((err) => {
						console.log(err);
						setTimeout(() => {
							clickedResetRulesDestination.value = false;
						}, 250);
					});
			}
		}
		if (action == 'resetRulesPricing') {
			if (clickedResetRulesPricing.value == false) {
				clickedResetRulesPricing.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/precificacoes/voltaRegraPrecificacaoPadrao`,
						{
							id_destino: tipoPrecificacao.value,
							id_veiculo_tipo: tipoVeiculo.value,
							id_metal: metal.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						setTimeout(() => {
							margemNegociacao.value = res.data.dados.margem_percentual ? String((res.data.dados.margem_percentual * 100).toFixed(2)) : '';
							margemMinima.value = res.data.dados.margem_minima ? String(res.data.dados.margem_minima) : '';
							margemMaxima.value = res.data.dados.margem_maxima ? String(res.data.dados.margem_maxima) : '';
							lucro.value = res.data.dados.lucro_percentual ? (res.data.dados.lucro_percentual * 100).toFixed(2) : '';
							lucroMinimo.value = res.data.dados.lucro_minimo ? String(res.data.dados.lucro_minimo) : '';
							lucroMaximo.value = res.data.dados.lucro_maximo ? String(res.data.dados.lucro_maximo) : '';
							higienizacaoCusto.value = res.data.dados.higienizacao ? String(res.data.dados.higienizacao) : '';
							polimentoCusto.value = res.data.dados.polimento ? String(res.data.dados.polimento) : '';
							tempoEstoqueMaximo.value = res.data.dados.tempovenda_maximo ? String(res.data.dados.tempovenda_maximo) : '';
							tempoEstoqueMinimo.value = res.data.dados.tempovenda_minimo ? String(res.data.dados.tempovenda_minimo) : '';
							acrescimoParaReparo.value = res.data.dados.dias_para_reparo ? String(res.data.dados.dias_para_reparo) : '';
							depreciacaoFipe.value = res.data.dados.depreciacao_fipe ? true : false;
							depreciacaoCapital.value = res.data.dados.depreciacao_capital ? true : false;
							depreciacaoCapitalValue.value = res.data.dados.depreciacao_capital ? String((res.data.dados.depreciacao_capital * 100).toFixed(2)) : '';
							carenciaFloorplan.value = res.data.dados.depreciacao_capital_carencia ? String(res.data.dados.depreciacao_capital_carencia) : '';
							aliquotaIpva.value = res.data.dados.ipva ? String((res.data.dados.ipva * 100).toFixed(2)) : '';
							aliquotaIcms.value = res.data.dados.icms ? String((res.data.dados.icms * 100).toFixed(2)) : '';
							aliquotaPisCofins.value = res.data.dados.pis_cofins ? String((res.data.dados.pis_cofins * 100).toFixed(2)) : '';
							lucroRepassador.value = res.data.dados.lucro_repassador ? String((res.data.dados.lucro_repassador * 100).toFixed(2)) : '';
							percentualFipeCustosRevisao.value = res.data.dados.revisoes_percentual ? String((res.data.dados.revisoes_percentual * 100).toFixed(2)) : '';
							clickedResetRulesPricing.value = false;
						}, 200);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}
	if (type == 'advance') {
		if (action == 'editAcess') {
			modalControlAcessUser.value = true;
		}
		if (action == 'addNewUser') {
			modalAddUserOpen.value = true;
		}
		if (action == 'addNewGroup') {
			modalAddGroup.value = true;
		}
		if (action == 'copySignature') {
			if (navigator.clipboard && navigator.clipboard.write) {
				const item = new ClipboardItem({
					'text/plain': new Blob([signature.value], { type: 'text/plain' })
				});
				navigator.clipboard
					.write([item])
					.then(() => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Texto copiado',
									pText: 'Texto copiado com sucesso para sua área de transferência'
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false
							}
						);
					})
					.catch((err) => {
						toast.warning(
							{
								component: toastText,
								props: {
									h1Text: 'Texto não copiado',
									pText: 'Não foi possivel copiar o texto para sua área de transferência'
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false
							}
						);
					});
			} else {
				console.error('Clipboard API is not supported');
			}
		}
		// if (action == 'adquirirVeiculo') {
		// 	const cookies = document.cookie.split(';');
		// 	const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
		// 	const tokenUser = cookies[tokenIndex].split('=')[1];
		// 	if (clickedButtonAdquireVeiculo.value == false) {
		// 		clickedButtonAdquireVeiculo.value = true;
		// 		axios
		// 			.post(
		// 				`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/adquireAvaliacao`,
		// 				{
		// 					id_avaliacao: id_avaliacao.value
		// 				},
		// 				{
		// 					headers: {
		// 						Authorization: 'Bearer ' + tokenUser,
		// 						'Content-Type': 'application/json'
		// 					}
		// 				}
		// 			)
		// 			.then((res) => {
		// 				toast.success(
		// 					{
		// 						component: toastText,
		// 						props: {
		// 							h1Text: 'Veículo adquirido',
		// 							pText: res.data.mensagem
		// 						}
		// 					},
		// 					{
		// 						timeout: 2500,
		// 						closeOnClick: false,
		// 						pauseOnFocusLoss: false,
		// 						pauseOnHover: false,
		// 						draggable: true,
		// 						draggablePercent: 0.8,
		// 						showCloseButtonOnHover: false,
		// 						hideProgressBar: false,
		// 						closeButton: false,
		// 						icon: sucessoIcon,
		// 						rtl: false,
		// 						onClose() {
		// 							clickedButtonAdquireVeiculo.value = false;
		// 							evaluationView.$reset();
		// 							router.push({ name: 'inicio' });
		// 						}
		// 					}
		// 				);
		// 			})
		// 			.catch((err) => {
		// 				console.log(err);
		// 			});
		// 	}
		// }

		if (action == 'newEvaluation') {
			if (mobile.value) {
				router.push({ name: 'avaliacao' });
			} else {
				router.push({ name: 'avaliacao' }).then(() => {
					tipoAvaliacao.value = 1;
					currentStep.value = 1;
				});
			}
		}

		if (action === 'adquirirAvaliacao') {
			const cookies = document.cookie.split(';');
			const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
			const tokenUser = cookies[tokenIndex].split('=')[1];

			if (clickedButtonAdquirirAvaliacao.value == false) {
				clickedButtonAdquirirAvaliacao.value = true;
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/alteraStatusAvaliacao`,
						{
							id_avaliacao: id_avaliacao.value,
							id_status: REVIEWS_STATUS_CONFIG.ACQUIRED
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Avaliação adquirida com sucesso',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									clickedButtonAdquirirAvaliacao.value = false;
									evaluationView.$reset();
									router.push({ name: 'avaliacoes' });
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}

		if (action === 'venderVeiculo') {
			const cookies = document.cookie.split(';');
			const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
			const tokenUser = cookies[tokenIndex].split('=')[1];

			if (clickedButtonVenderVeiculo.value == false) {
				clickedButtonVenderVeiculo.value = true;
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/alteraStatusAvaliacao`,
						{
							id_avaliacao: id_avaliacao.value,
							id_status: REVIEWS_STATUS_CONFIG.SOLD
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Veículo vendido com sucesso',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									clickedButtonVenderVeiculo.value = false;
									evaluationView.$reset();
									router.push({ name: 'avaliacoes' });
								}
							}
						);
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	}
	if (type == 'empty') {
		if (action == 'forgotPassword') {
			router.push({ name: 'recuperarSenha' });
		}
		if (action == 'leaveForgotPassword') {
			router.push('/');
		}
		if (action == 'editAcess') {
			router.push({ name: 'controleUsuarioAcessos' });
		}
		if (action == 'copySignature') {
			const item = new ClipboardItem({
				'text/plain': new Blob([signature.value], { type: 'text/plain' })
			});
			navigator.clipboard
				.write([item])
				.then(() => {
					toast.success(
						{
							component: toastText,
							props: {
								h1Text: 'Texto copiado',
								pText: 'Texto copiado com sucesso para sua área de transferência'
							}
						},
						{
							timeout: 2500,
							closeOnClick: false,
							pauseOnFocusLoss: false,
							pauseOnHover: false,
							draggable: true,
							draggablePercent: 0.8,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: false,
							icon: sucessoIcon,
							rtl: false
						}
					);
				})
				.catch((err) => {
					toast.warning(
						{
							component: toastText,
							props: {
								h1Text: 'Texto não copiado',
								pText: 'Não foi possivel copiar o texto para sua área de transferência'
							}
						},
						{
							timeout: 2500,
							closeOnClick: false,
							pauseOnFocusLoss: false,
							pauseOnHover: false,
							draggable: true,
							draggablePercent: 0.8,
							showCloseButtonOnHover: false,
							hideProgressBar: false,
							closeButton: false,
							icon: sucessoIcon,
							rtl: false
						}
					);
				});
		}
	}
	if (type == 'destructive') {
		if (action == 'deleteUser') {
			if (modalDeleteUser.value == true) {
				if (clickButtonUserControl.value == false) {
					clickButtonUserControl.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					if (transfUserTeam.value == '') {
						haveAErrorUserControl.value = true;
						errorUserControl.value = true;
						errorTextUserControl.value = 'Equipe não informada';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					} else if (transfUserGroup.value == '') {
						haveAErrorUserControl.value = true;
						errorUserControl.value = true;
						errorTextUserControl.value = 'Grupo não informado';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					} else if (transfUserName.value == '') {
						haveAErrorUserControl.value = true;
						errorUserControl.value = true;
						errorTextUserControl.value = 'Usuário não informado';
						setTimeout(() => {
							clickButtonUserControl.value = false;
						}, 1500);
					} else {
						axios
							.post(
								`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/desativaUsuario`,
								{
									email: email.value,
									email_usuario_desativar: editUserEmail.value,
									senha: deleteUserPassword.value,
									idUsuarioTransf: transfUserId.value
								},
								{
									headers: {
										Authorization: 'Bearer ' + tokenUser,
										'Content-Type': 'application/json'
									}
								}
							)
							.then((res) => {
								let resulDesativa = res;
								axios
									.post(
										`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/transfereAvaliacoes`,
										{
											id_usuario_delete: editUserId.value,
											nome_usuario_delete: editUserName.value,
											sobrenome_usuario_delete: editUserSurname.value,
											id_empresa_usuario_delete: id_empresa.value,
											id_equipe_usuario_delete: editUserTeamId.value,
											id_grupo_usuario_delete: editUserGroupId.value,
											empresa_usuario_delete: empresa.value,
											equipe_usuario_delete: editUserTeam.value,
											grupo_usuario_delete: editUserGroup.value,
											id_usuario_transfere: transfUserId.value,
											nome_usuario_transfere: transfUserName.value,
											sobrenome_usuario_transfere: transfUserSurname.value,
											id_empresa_usuario_transfere: id_empresa.value,
											id_equipe_usuario_transfere: transfUserTeamId.value,
											id_grupo_usuario_transfere: transfUserGroupId.value,
											empresa_usuario_transfere: empresa.value,
											equipe_usuario_transfere: transfUserTeam.value,
											grupo_usuario_transfere: transfUserGroup.value
										},
										{
											headers: {
												Authorization: 'Bearer ' + tokenUser,
												'Content-Type': 'application/json'
											}
										}
									)
									.then(() => {
										toast.success(
											{
												component: toastText,
												props: {
													h1Text: 'Usuário desativado',
													pText: resulDesativa.data.mensagem
												}
											},
											{
												timeout: 2500,
												closeOnClick: false,
												pauseOnFocusLoss: false,
												pauseOnHover: false,
												draggable: true,
												draggablePercent: 0.8,
												showCloseButtonOnHover: false,
												hideProgressBar: false,
												closeButton: false,
												icon: sucessoIcon,
												rtl: false,
												onClose() {
													refreshListUsers.value = true;
													setTimeout(() => {
														controlUser.$reset();
													}, 100);
												}
											}
										);
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									});
							})
							.catch((err) => {
								haveAErrorUserControl.value = true;
								if (err.message == 'Network Error') {
									toast.error(
										{
											component: toastText,
											props: {
												h1Text: 'Serviço indisponível',
												pText: 'serviço passando por instabilidade'
											}
										},
										{
											timeout: 5000,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: insucessoIcon,
											rtl: false
										}
									);
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								} else {
									if (deleteUserPassword.value.length > 0) {
										errorUserControl.value = true;
										errorTextUserControl.value = err.response.data;
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									} else {
										errorUserControl.value = true;
										errorTextUserControl.value = 'Senha não informada';
										setTimeout(() => {
											clickButtonUserControl.value = false;
										}, 1500);
									}
								}
							});
					}
				}
			} else {
				modalDeleteUser.value = true;
			}
		}
		if (action == 'deleteGroup') {
			if (modalDeleteGroup.value == true) {
				if (clickButtonGroupTeamControl.value == false) {
					clickButtonGroupTeamControl.value = true;
					const cookies = document.cookie.split(';');
					const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
					const tokenUser = cookies[tokenIndex].split('=')[1];
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/grupos/apagaGrupo`,
							{
								email: email.value,
								id_grupo: groupId.value,
								senha: deleteGroupPassword.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Desativado',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										refreshListGroups.value = true;
										setTimeout(() => {
											controlGroupTeam.$reset();
										}, 100);
									}
								}
							);
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						})
						.catch((err) => {
							haveAErrorGroupTeamControl.value = true;
							if (err.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else {
								if (err.response.data.mensagem == 'Grupo contem usuários ativos ou não pertence à empresa do usuário') {
									toast.warning(
										{
											component: toastText,
											props: {
												h1Text: err.response.data.mensagem,
												pText: 'desative ou transfira estes usuários'
											}
										},
										{
											timeout: 2500,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: avisoIcon,
											rtl: false
										}
									);
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								} else {
									errorGroupTeamControl.value = true;
									errorTextGroupTeamControl.value = err.response.data.mensagem;
									setTimeout(() => {
										clickButtonGroupTeamControl.value = false;
									}, 1500);
								}
							}
						});
				}
			} else {
				modalDeleteGroup.value = true;
			}
		}
		if (action == 'updateSignature') {
			signatureModalOpen.value = true;
		}
		if (action == 'evaluationEdit') {
			if (desktop.value) {
				router.back();
				setTimeout(() => {
					currentStep.value = 0;
				}, 400);
			}
		}
	}
	if (type == 'destructiveFill') {
		if (action == 'deleteUser') {
			if (clickButtonUserControl.value == false) {
				clickButtonUserControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (transfUserTeam.value == '') {
					haveAErrorUserControl.value = true;
					errorUserControl.value = true;
					errorTextUserControl.value = 'Equipe não informada';
					setTimeout(() => {
						clickButtonUserControl.value = false;
					}, 1500);
				} else if (transfUserGroup.value == '') {
					haveAErrorUserControl.value = true;
					errorUserControl.value = true;
					errorTextUserControl.value = 'Grupo não informado';
					setTimeout(() => {
						clickButtonUserControl.value = false;
					}, 1500);
				} else if (transfUserName.value == '') {
					haveAErrorUserControl.value = true;
					errorUserControl.value = true;
					errorTextUserControl.value = 'Usuário não informado';
					setTimeout(() => {
						clickButtonUserControl.value = false;
					}, 1500);
				} else {
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/desativaUsuario`,
							{
								email: email.value,
								email_usuario_desativar: editUserEmail.value,
								senha: deleteUserPassword.value,
								idUsuarioTransf: transfUserId.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							let resulDesativa = res;
							axios
								.post(
									`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/transfereAvaliacoes`,
									{
										id_usuario_delete: editUserId.value,
										nome_usuario_delete: editUserName.value,
										sobrenome_usuario_delete: editUserSurname.value,
										id_empresa_usuario_delete: id_empresa.value,
										id_equipe_usuario_delete: editUserTeamId.value,
										id_grupo_usuario_delete: editUserGroupId.value,
										empresa_usuario_delete: empresa.value,
										equipe_usuario_delete: editUserTeam.value,
										grupo_usuario_delete: editUserGroup.value,
										id_usuario_transfere: transfUserId.value,
										nome_usuario_transfere: transfUserName.value,
										sobrenome_usuario_transfere: transfUserSurname.value,
										id_empresa_usuario_transfere: id_empresa.value,
										id_equipe_usuario_transfere: transfUserTeamId.value,
										id_grupo_usuario_transfere: transfUserGroupId.value,
										empresa_usuario_transfere: empresa.value,
										equipe_usuario_transfere: transfUserTeam.value,
										grupo_usuario_transfere: transfUserGroup.value
									},
									{
										headers: {
											Authorization: 'Bearer ' + tokenUser,
											'Content-Type': 'application/json'
										}
									}
								)
								.then(() => {
									toast.success(
										{
											component: toastText,
											props: {
												h1Text: 'Desativado',
												pText: resulDesativa.data.mensagem
											}
										},
										{
											timeout: 2500,
											closeOnClick: false,
											pauseOnFocusLoss: false,
											pauseOnHover: false,
											draggable: true,
											draggablePercent: 0.8,
											showCloseButtonOnHover: false,
											hideProgressBar: false,
											closeButton: false,
											icon: sucessoIcon,
											rtl: false,
											onClose() {
												refreshListUsers.value = true;
												setTimeout(() => {
													controlUser.$reset();
												}, 100);
											}
										}
									);
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								});
						})
						.catch((err) => {
							haveAErrorUserControl.value = true;
							if (err.message == 'Network Error') {
								toast.error(
									{
										component: toastText,
										props: {
											h1Text: 'Serviço indisponível',
											pText: 'serviço passando por instabilidade'
										}
									},
									{
										timeout: 5000,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: insucessoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonUserControl.value = false;
								}, 1500);
							} else {
								if (deleteUserPassword.value.length > 0) {
									errorUserControl.value = true;
									errorTextUserControl.value = err.response.data;
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								} else {
									errorUserControl.value = true;
									errorTextUserControl.value = 'Senha não informada';
									setTimeout(() => {
										clickButtonUserControl.value = false;
									}, 1500);
								}
							}
						});
				}
			}
		}
		if (action == 'deleteGroup') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/grupos/apagaGrupo`,
						{
							email: email.value,
							id_grupo: groupId.value,
							senha: deleteGroupPassword.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Desativado',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									refreshListGroups.value = true;
									setTimeout(() => {
										controlGroupTeam.$reset();
									}, 100);
								}
							}
						);
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					})
					.catch((err) => {
						haveAErrorGroupTeamControl.value = true;
						if (err.message == 'Network Error') {
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Serviço indisponível',
										pText: 'serviço passando por instabilidade'
									}
								},
								{
									timeout: 5000,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						} else {
							errorGroupTeamControl.value = false;
							errorTextGroupTeamControl.value = '';
							if (err.response.data.mensagem == 'Grupo contem usuários ativos ou não pertence à empresa do usuário') {
								toast.warning(
									{
										component: toastText,
										props: {
											h1Text: err.response.data.mensagem,
											pText: 'desative ou transfira estes usuários'
										}
									},
									{
										timeout: 2500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: avisoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else {
								errorGroupTeamControl.value = true;
								errorTextGroupTeamControl.value = err.response.data.mensagem;
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							}
						}
					});
			}
		}
		if (action == 'deleteTeam') {
			if (clickButtonGroupTeamControl.value == false) {
				clickButtonGroupTeamControl.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/equipes/apagaEquipe`,
						{
							email: email.value,
							id_equipe: teamId.value,
							senha: deleteGroupPassword.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Desativado',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									refreshListGroups.value = true;
									setTimeout(() => {
										controlGroupTeam.$reset();
									}, 100);
								}
							}
						);
						setTimeout(() => {
							clickButtonGroupTeamControl.value = false;
						}, 1500);
					})
					.catch((err) => {
						haveAErrorGroupTeamControl.value = true;
						if (err.message == 'Network Error') {
							toast.error(
								{
									component: toastText,
									props: {
										h1Text: 'Serviço indisponível',
										pText: 'serviço passando por instabilidade'
									}
								},
								{
									timeout: 5000,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: insucessoIcon,
									rtl: false
								}
							);
							setTimeout(() => {
								clickButtonGroupTeamControl.value = false;
							}, 1500);
						} else {
							errorGroupTeamControl.value = false;
							errorTextGroupTeamControl.value = '';
							if (err.response.data.mensagem == 'Equipe contem grupos ativos ou não pertence à empresa do usuário') {
								toast.warning(
									{
										component: toastText,
										props: {
											h1Text: err.response.data.mensagem,
											pText: 'desative ou transfira estes grupos'
										}
									},
									{
										timeout: 2500,
										closeOnClick: false,
										pauseOnFocusLoss: false,
										pauseOnHover: false,
										draggable: true,
										draggablePercent: 0.8,
										showCloseButtonOnHover: false,
										hideProgressBar: false,
										closeButton: false,
										icon: avisoIcon,
										rtl: false
									}
								);
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else if (deleteGroupPassword.value.length > 0) {
								errorGroupTeamControl.value = true;
								errorTextGroupTeamControl.value = err.response.data.mensagem;
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							} else {
								errorGroupTeamControl.value = true;
								errorTextGroupTeamControl.value = 'Senha não informada';
								setTimeout(() => {
									clickButtonGroupTeamControl.value = false;
								}, 1500);
							}
						}
					});
			}
		}
		if (action == 'updateSignature') {
			if (clickButtonUpdateSignature.value == false) {
				clickButtonUpdateSignature.value = true;
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				axios
					.post(
						`${import.meta.env.VITE_AUTO_ME_USUARIO}/api/atualizaAssinatura`,
						{
							email: email.value,
							senha: passwordUpdateSignature.value,
							id_empresa: id_empresa.value
						},
						{
							headers: {
								Authorization: 'Bearer ' + tokenUser,
								'Content-Type': 'application/json'
							}
						}
					)
					.then((res) => {
						toast.success(
							{
								component: toastText,
								props: {
									h1Text: 'Assinatura atualizada',
									pText: res.data.mensagem
								}
							},
							{
								timeout: 2500,
								closeOnClick: false,
								pauseOnFocusLoss: false,
								pauseOnHover: false,
								draggable: true,
								draggablePercent: 0.8,
								showCloseButtonOnHover: false,
								hideProgressBar: false,
								closeButton: false,
								icon: sucessoIcon,
								rtl: false,
								onClose() {
									signatureModalOpen.value = false;
									passwordUpdateSignature.value = '';
									clickButtonUpdateSignature.value = false;
									loadNewSignature.value = true;
									setTimeout(() => {
										loadNewSignature.value = false;
										signature.value = res.data.dados.assinatura;
									}, 600);
								}
							}
						);
					})
					.catch((err) => {
						errorUpdateSignature.value = true;
						errorUpdateSignatureText.value = err.response.data.mensagem;
						setTimeout(() => {
							clickButtonUpdateSignature.value = false;
						}, 600);
					});
			}
		}
		if (action == 'recusarRenegociacao') {
			if (modalComentarioRenegociacao.value == true) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (clickedRecusaRenegociacao.value == false && clickedAceitaRenegociacao.value == false) {
					clickedRecusaRenegociacao.value = true;
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/renegociacoes/recusaRenegociacoes`,
							{
								id_avaliacao: id_avaliacao.value,
								usuario: {
									id_usuario: idUsuario.value,
									nome: nome.value,
									sobrenome: sobrenome.value,
									grupo: {
										id_grupo: id_grupo.value,
										grupo: grupo.value
									},
									equipe: {
										id_equipe: id_equipe.value,
										equipe: equipe.value
									},
									empresa: {
										id_empresa: id_empresa.value,
										empresa: empresa.value
									}
								},
								acessos: acessos.value,
								comentario: comentarioRenegociacao.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Renegociacao recusada',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										clickedRecusaRenegociacao.value = true;
										modalComentarioRenegociacao.value = false;
										router.push({ name: 'avaliacoes' });
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
							clickedRecusaRenegociacao.value = false;
						});
				}
			} else {
				typeComentario.value = 'recusaRenegociacao';
				modalComentarioRenegociacao.value = true;
			}
		}
		if (action == 'vetarRenegociacao') {
			if (modalComentarioRenegociacao.value == true) {
				const cookies = document.cookie.split(';');
				const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
				const tokenUser = cookies[tokenIndex].split('=')[1];
				if (clickedVetaRenegociacao.value == false && clickedAceitaRenegociacao.value == false) {
					clickedVetaRenegociacao.value = true;
					axios
						.post(
							`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/renegociacoes/vetaRenegociacao`,
							{
								id_avaliacao: id_avaliacao.value,
								usuario: {
									id_usuario: idUsuario.value,
									nome: nome.value,
									sobrenome: sobrenome.value,
									grupo: {
										id_grupo: id_grupo.value,
										grupo: grupo.value
									},
									equipe: {
										id_equipe: id_equipe.value,
										equipe: equipe.value
									},
									empresa: {
										id_empresa: id_empresa.value,
										empresa: empresa.value
									}
								},
								acessos: acessos.value,
								comentario: comentarioRenegociacao.value
							},
							{
								headers: {
									Authorization: 'Bearer ' + tokenUser,
									'Content-Type': 'application/json'
								}
							}
						)
						.then((res) => {
							toast.success(
								{
									component: toastText,
									props: {
										h1Text: 'Renegociacao vetada',
										pText: res.data.mensagem
									}
								},
								{
									timeout: 2500,
									closeOnClick: false,
									pauseOnFocusLoss: false,
									pauseOnHover: false,
									draggable: true,
									draggablePercent: 0.8,
									showCloseButtonOnHover: false,
									hideProgressBar: false,
									closeButton: false,
									icon: sucessoIcon,
									rtl: false,
									onClose() {
										clickedVetaRenegociacao.value = true;
										modalComentarioRenegociacao.value = false;
										router.push({ name: 'avaliacoes' });
									}
								}
							);
						})
						.catch((err) => {
							console.log(err);
							clickedVetaRenegociacao.value = false;
						});
				}
			} else {
				typeComentario.value = 'vetaRenegociacao';
				modalComentarioRenegociacao.value = true;
			}
		}
	}
}
function atualizarAtivos(array: any) {
	for (let i = 0; i < array.length; i++) {
		const idAcesso = array[i];
		const acesso = acessosControl.value.find((acesso: any) => acesso.id_acesso === idAcesso);
		if (acesso && acesso.ativo === 1) {
			for (let j = 0; j <= i; j++) {
				const idAcessoAnterior = array[j];
				const acessoAnterior = acessosControl.value.find((acesso: any) => acesso.id_acesso === idAcessoAnterior);
				if (acessoAnterior) {
					acessoAnterior.ativo = 1;
				}
			}
		}
	}
}
async function listAval() {
	const cookies = document.cookie.split(';');
	const tokenIndex = cookies.findIndex((cookie) => cookie.startsWith('token_user=') || cookie.startsWith(' token_user='));
	const tokenUser = cookies[tokenIndex].split('=')[1];
	const cacheKey = 'avaliacoes_cache';
	sessionStorage.removeItem(cacheKey);
	filters.$reset();

	async function fetchData() {
		const response = await axios.post(
			`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/filtrosListaAvaliacoes`,
			{
				pagina: pagina.value,
				empresa: {
					id_empresa: id_empresa.value
				},
				equipe: {
					id_equipe: id_equipe.value
				},
				grupo: {
					id_grupo: id_grupo.value
				},
				usuario: {
					id_usuario: idUsuario.value
				},
				acessos: acessos.value,
				filtros: {
					marca: '',
					modelo: '',
					placa: '',
					equipe: '',
					grupo: '',
					usuario: '',
					blindagem: '',
					teto_solar: '',
					status: '',
					renegociado: '',
					ano_modelo: '',
					data_inicial: undefined,
					data_final: undefined
				}
			},
			{
				headers: {
					Authorization: 'Bearer ' + tokenUser,
					'Content-Type': 'application/json'
				}
			}
		);

		return response.data.dados;
	}
	let cachedData = sessionStorage.getItem(cacheKey);
	let cachedFirstItem = cachedData ? JSON.parse(cachedData).avaliacoes[0] : null;
	let cachedFilters = cachedData ? JSON.parse(cachedData).filtros : null;

	const newData = await fetchData();
	if (!cachedData || newData.avaliacoes[0].data_criacao !== cachedFirstItem.data_criacao || newData.filtros !== cachedFilters) {
		sessionStorage.setItem('last_changes_avaliacoes_cache', `${new Date()}`);
		sessionStorage.setItem(cacheKey, JSON.stringify(newData));
	}
}
</script>

<template>
	<div
		class="button"
		@click="goAhead(type, action)"
		:class="{
			Button32Height: customHeight == 32,
			buttonDeactive: type == 'deactive' || color == 'deactive',
			buttonActive: type == 'active' || color == 'active',
			buttonEmpty: type == 'empty' || color == 'empty',
			buttonDestructive: type == 'destructive' || color == 'destructive',
			buttonDestructiveFill: type == 'destructiveFill' || color == 'destructiveFill',
			buttonConfirm: type == 'confirm' || color == 'confirm',
			buttonActiveUnfill: type == 'activeUnfill' || color == 'activeUnfill',
			buttonAdvance: type == 'advance' || color == 'advance',
			buttonConfirmDeative: color == 'confirmDeactive'
		}"
	>
		<p v-if="clickButton == false && type !== 'empty' && action == 'login'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonForgot == false && type !== 'empty' && action == 'forgotPassword'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'copySignature'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'custom'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'active' && action == 'saveChangesAcess' && clickedAcess == false">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedSolicitaAvaliacao == false && type == 'confirm' && action == 'requestEvaluation'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedSolicitaAvaliacao == false && type == 'active' && action == 'requestEvaluation'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedSaveChangesDestination == false && type == 'active' && action == 'saveChangesRulesDestination' && clickedAcess == false">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedSaveChangesPricing == false && (type == 'active' || type == 'deactive') && action == 'saveChangesRulesPricing'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'activeUnfill' && action == 'resetAcess' && clickedResetAcess == false">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedResetRulesDestination == false && type == 'activeUnfill' && action == 'resetRulesDestination' && clickedResetAcess == false">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedResetRulesPricing == false && type == 'activeUnfill' && action == 'resetRulesPricing'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'destructive' && action == 'updateSignature'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'destructiveFill' && action == 'updateSignature' && clickButtonUpdateSignature == false">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonUserControl == false && type !== 'empty' && type == 'destructiveFill' && action == 'deleteUser'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonUserControl == false && type !== 'empty' && type == 'destructive' && action == 'deleteUser'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'goToInicio'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && action === 'deleteGroup'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && type == 'destructive' && action == 'deleteTeam'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && type == 'destructiveFill' && action == 'deleteTeam'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonUserControl == false && type !== 'empty' && type == 'confirm' && action == 'addNewUser'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonUserControl == false && type !== 'empty' && type == 'active' && action == 'addNewUser'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && type == 'advance' && action == 'addNewUser'">
			<slot name="labelButton"></slot>
		</p>
		<!-- <p v-if="type !== 'empty' && type == 'advance' && action == 'adquirirVeiculo'">
			<slot name="labelButton"></slot>
		</p> -->
		<p v-if="type !== 'empty' && type == 'advance' && action == 'adquirirAvaliacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && type == 'advance' && action == 'venderVeiculo'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedButtonPagaAvaliacao == false && type !== 'empty' && type == 'active' && action == 'payEvaluation'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && action == 'addNewTeam'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonCompanyControl == false && type !== 'empty' && action == 'addNewCompany'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonForgot == false && type !== 'empty' && action == 'changePassword'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && type == 'active' && action == 'editGroup'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && type == 'active' && action == 'editTeam'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonGroupTeamControl == false && type !== 'empty' && action == 'addNewGroup'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'backRoute'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedSendEvaluation == false && type !== 'empty' && action == 'sendEvaluation'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'newEvaluation'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'evaluationNext'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && action == 'evaluationEdit'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type !== 'empty' && type == 'advance' && action == 'editAcess'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedAutoPay == false && type !== 'empty' && type == 'active' && action == 'saveChangesRulesAutoPay'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedButtonEditValorFinal == false && type !== 'empty' && type == 'active' && action == 'saveChangesValorFinal'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonUserControl == false && type !== 'empty' && type == 'active' && action == 'editUser'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedButtonDesvioPagamento == false && type !== 'empty' && type == 'active' && action == 'saveChangesDesvioPagamento'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonCompanyControl == false && type !== 'empty' && type == 'active' && action == 'saveChangesAvaliacoesMensais'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedButtonEditRenegociacao == false && type !== 'empty' && type == 'active' && action == 'saveChangesRenegociacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedAceitaRenegociacao == false && clickedRecusaRenegociacao == false && clickedVetaRenegociacao == false && type !== 'empty' && type == 'confirm' && action == 'aceitarRenegociacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedRecusaRenegociacao == false && clickedAceitaRenegociacao == false && type !== 'empty' && type == 'destructiveFill' && action == 'recusarRenegociacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedVetaRenegociacao == false && clickedAceitaRenegociacao == false && type !== 'empty' && type == 'destructiveFill' && action == 'vetarRenegociacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickedButtonEditRenegociacao == false && type !== 'empty' && type == 'active' && action == 'sendRenegociacao'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="clickButtonChangePassword == false && type !== 'empty' && action == 'saveNewPassword'">
			<slot name="labelButton"></slot>
		</p>
		<p v-if="type == 'empty'">
			<slot name="labelButton"></slot>
		</p>
		<loader v-if="(clickButton == true || clickButtonForgot == true || clickButtonChangePassword == true || clickButtonUserControl == true || clickButtonGroupTeamControl == true || clickButtonUpdateSignature == true || clickedAcess == true || clickedResetAcess == true || clickedSaveChangesDestination == true || clickedResetRulesDestination == true || clickedResetRulesPricing == true || clickedSaveChangesPricing == true || clickedButtonEditValorFinal == true || clickedButtonDesvioPagamento == true || clickedButtonEditRenegociacao == true || (clickedAceitaRenegociacao == true && clickedRecusaRenegociacao == false && clickedVetaRenegociacao == false) || (clickedRecusaRenegociacao == true && clickedAceitaRenegociacao == false) || (clickedVetaRenegociacao == true && clickedAceitaRenegociacao == false) || clickButtonCompanyControl == true || clickedButtonPagaAvaliacao == true || clickedSendEvaluation == true || clickedAutoPay == true || clickedSolicitaAvaliacao == true) && type !== 'empty' && type !== 'advance' && type !== 'custom'"> </loader>
		<loader v-if="(clickedButtonVenderVeiculo == true || clickedButtonAdquirirAvaliacao == true) && type === 'advance'" />
	</div>
</template>
